import React from 'react';

import helpers from '../../commons/helpers/helpers';
import { RepairListView } from '../repairTypes';
import { DateString } from '../../commons/model/common';

type Props = {
  repair: RepairListView;
  onRowClick: (repairId: string) => void;
};

const formatDate = (dateTime: DateString) => helpers.dateTimeFormat(dateTime, 'DD/MM/YYYY');

const getClassNameForStatus = (repair: RepairListView) => {
  if (repair.status === 'DONE') {
    return 'done';
  } else {
    return 'in-progress';
  }
};

const RepairRow = ({ repair, onRowClick }: Props) => (
  <div className={`table-body-row row-${getClassNameForStatus(repair)}`} onClick={() => onRowClick(repair.id)}>
    <div className="cell-repair-number">{repair.repairNumber}</div>
    <div className="cell-wagon-registration-number">{helpers.registrationFormat(repair.wagonRegistrationNumber)}</div>
    <div className="cell-damage-report-number">{repair.damageReportNumber}</div>
    <div className="cell-intervention_request_date">{formatDate(repair.interventionRequestDate)}</div>
    <div className="cell-start-date">{formatDate(repair.repairStartDate)}</div>
    <div className="cell-end-date">{formatDate(repair.repairEndDate)}</div>
    <div className="cell-duration">{repair.duration}</div>
    <div className="cell-market-name">{repair.marketName}</div>
    <div className="cell-market-axis">{repair.marketAxis}</div>
    <div className="cell-workshop-name">{repair.workshopName}</div>
    <div className="cell-accident-number">{repair.accidentNumber}</div>
  </div>
);

export default RepairRow;
