import { PayloadAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { DamageReportLabelKey } from '../../../../commons/model/DamageReport';
import { AppDispatch } from '../../../../commons/store/store';
import apiHelper from '../../../../api/apiHelper';
import { startToast } from '../../../../commons/components/toast/toastDucks';
import { Moment } from 'moment';
import { loadTemplates } from '../../../../commons/templates/templatesDucks';
import { loadLibrary, startEditWagon } from '../../vehiclesLibraryDuck';
import { WagonTemplate } from '../../../../commons/model/templates';
import wagonCache from '../../../../commons/templates/wagonCache';

export const OPEN_EXTERNAL_DAMAGE_REPORT_DIALOG = 'OPEN_EXTERNAL_DAMAGE_REPORT_DIALOG';
export const CLOSE_EXTERNAL_DAMAGE_REPORT_DIALOG = 'CLOSE_EXTERNAL_DAMAGE_REPORT_DIALOG';

export type ExternalDamageReportModalFormValues = {
  registration: string;
  damages: { damageTemplateId: string; comment: string }[];
  validatedDate: Moment | null;
  labels: DamageReportLabelKey[];
};

type ExternalDamageReportModalConfig = {
  registration: string;
};

export type ExternalDamageReportModalState = Required<ExternalDamageReportModalConfig> &
  ExternalDamageReportModalFormValues & {
    hidden: boolean;
    onCancel?: () => void;
  };

const initialState: ExternalDamageReportModalState = {
  registration: '',
  onCancel: () => {},
  hidden: true,
  damages: [{ damageTemplateId: '', comment: '' }],
  validatedDate: null,
  labels: [],
};

export default (state = initialState, action: AnyAction): ExternalDamageReportModalState => {
  switch (action.type) {
    case OPEN_EXTERNAL_DAMAGE_REPORT_DIALOG:
      return { ...initialState, hidden: false, ...action.payload };
    case CLOSE_EXTERNAL_DAMAGE_REPORT_DIALOG:
      return { ...initialState, hidden: true };
    default:
      return state;
  }
};

export const openExternalDamageReportDialog = ({
  registration,
}: ExternalDamageReportModalConfig): PayloadAction<ExternalDamageReportModalConfig> => ({
  type: OPEN_EXTERNAL_DAMAGE_REPORT_DIALOG,
  payload: { registration: registration },
});

export const closeExternalDamageReportDialog = (): PayloadAction<ExternalDamageReportModalConfig> => ({
  type: CLOSE_EXTERNAL_DAMAGE_REPORT_DIALOG,
  payload: { registration: '' },
});

export const submitExternalDamageReportDialog =
  (values: ExternalDamageReportModalFormValues) => (dispatch: AppDispatch) =>
    apiHelper
      .post('/api/external-damage-report', values)
      .then(async () => {
        dispatch({ type: CLOSE_EXTERNAL_DAMAGE_REPORT_DIALOG });
        dispatch(
          startToast({
            text: 'Le PVCA externe a bien été ajouté',
            className: 'success',
          }),
        );
        const savedTemplate: WagonTemplate = await apiHelper.get(`/api/wagon-templates/${values.registration}`);
        await wagonCache.addOrUpdateTemplatesFromLibrary([savedTemplate]);
        // update state
        dispatch(loadTemplates());
        dispatch(loadLibrary());
        dispatch(startEditWagon(savedTemplate));
      })
      .catch(() => {
        dispatch(
          startToast({
            text: "Une erreur est survenue, le PVCA externe n'a pas pu être ajouté",
            className: 'error',
          }),
        );
      });
