import AbstractTemplateCache from './AbstractTemplateCache';
import helpers from '../helpers/helpers';
import { CachedWagonTemplate, DamageTemplate, Owner, WagonTemplate } from '../model/templates';
import { Wagon } from '../model/Vehicle';
import { updateWagonTemplateWithCompositionWagon, updateWagonTemplateWithWagonTemplateCommand } from './templateUtils';
import { Tx } from './AbstractDbCache';
import DamageReportEntity from '../entity/DamageReportEntity';
import { Damage } from '../model/DamageReport';
import damageCache from './damageCache';

class WagonCache extends AbstractTemplateCache<'wagon', WagonTemplate> {
  protected getStoreName(): 'wagon' {
    return 'wagon';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 6;
  }

  protected normalizeInput(inputValue: string) {
    return helpers.registrationUnformat(inputValue);
  }

  protected addItemMetadata(item: WagonTemplate): CachedWagonTemplate {
    return { ...item, reversedRegistration: helpers.reverseString(item.registration) };
  }

  protected removeItemMetadata({ reversedRegistration, ...item }: CachedWagonTemplate): WagonTemplate {
    return item;
  }

  protected async findTemplates(inputValue: string, tx: Tx<'wagon'>) {
    const [registrationMatches, reversedRegistrationMatches] = await Promise.all([
      this.findItemsByIdPrefix(inputValue, tx),
      this.findItemsByIndexPrefix(helpers.reverseString(inputValue), tx, 'by-reversedRegistration'),
    ]);
    return helpers.deduplicateArray([...registrationMatches, ...reversedRegistrationMatches], 'registration');
  }

  public async updateTemplatesFromComposition(wagons: Wagon[]) {
    const { tx, store } = await this.connect('readwrite');
    await Promise.all(
      wagons.map(async (wagon) => {
        const dbTemplate = await store.get(wagon.registration!);
        const updatedTemplate = updateWagonTemplateWithCompositionWagon(dbTemplate, wagon);
        await store.put(this.addItemMetadata(updatedTemplate));
      }),
    );
    await tx.done;
  }

  public async addOrUpdateTemplatesFromLibrary(templates: WagonTemplate[]) {
    const { tx, store } = await this.connect('readwrite');
    await Promise.all(
      templates.map(async (template) => {
        const dbTemplate = await store.get(template.registration!);
        const updatedTemplate = updateWagonTemplateWithWagonTemplateCommand(dbTemplate, template);
        await store.put(this.addItemMetadata(updatedTemplate));
      }),
    );
    await tx.done;
  }

  public async deleteTemplates(templates: WagonTemplate[]) {
    const { tx, store } = await this.connect('readwrite');
    await Promise.all(templates.map((template) => store.delete(template.registration)));
    await tx.done;
  }

  public async reviewTemplates(registrations: string[]) {
    const { tx, store } = await this.connect('readwrite');
    await Promise.all(
      registrations.map(async (registration) => {
        const dbTemplate = (await store.get(registration))!;
        const updatedTemplate: CachedWagonTemplate = { ...dbTemplate, status: 'REVIEWED' };
        await store.put(updatedTemplate);
      }),
    );
    await tx.done;
  }

  public async updateWagonOwner(registration: string, owner: Owner | null) {
    const { tx, store } = await this.connect('readwrite');
    const dbTemplate = (await store.get(registration))!;
    const updatedTemplate: CachedWagonTemplate = {
      ...dbTemplate,
      ownerId: owner?.id ?? null,
      ownerName: owner?.name ?? null,
    };
    await store.put(updatedTemplate);
    await tx.done;
  }

  public async updateWagonTemplateAfterDamageReportValidation(registration: string, damageReport: DamageReportEntity) {
    const { tx, store } = await this.connect('readwrite');
    const dbTemplate = (await store.get(registration))!;

    if (!dbTemplate.inProgressDamageReports.find((dr) => dr.id === damageReport.id)) {
      const damageTemplates: DamageTemplate[] = await damageCache.findItemsByIds(
        damageReport.wagonDamages.damages.map((d: Damage) => d.damageTemplateId),
      );
      const updatedTemplate: CachedWagonTemplate = {
        ...dbTemplate,
        inProgressDamageReports: dbTemplate.inProgressDamageReports.concat([
          {
            id: damageReport.id,
            number: damageReport.number,
            labels: damageReport.newLabels,
            damageTemplates: damageTemplates.map((dt) => ({ id: dt.id, severity: dt.severity })),
            validatedDate: damageReport.validatedDate,
            repairStatus: null,
          },
        ]),
      };
      await store.put(updatedTemplate);
    }
    await tx.done;
  }

  public formatItem(item: WagonTemplate): string {
    return helpers.registrationFormat(item.registration);
  }
}

export default new WagonCache();
