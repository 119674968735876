import PropTypes from 'prop-types';

import { v4 as uuid } from 'uuid';
import AbstractOfflineEntity from './AbstractOfflineEntity';
import { CompanyOptions } from '../model/common';
import moment, { now } from 'moment';
import { RepairStatusLabels } from '../../repairs/repairTypes';

export default class DamageReportEntity extends AbstractOfflineEntity {
  id;
  number;
  createdBy;
  creationDate;
  validatedDate;
  newLabels;
  wagonDamages;
  market;
  wagon;
  company;
  repair;

  static reactShape() {
    return {
      id: PropTypes.string,
      number: PropTypes.number,
      createdBy: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      creationDate: PropTypes.string,
      reportDateTime: PropTypes.string,
      validatedDate: PropTypes.string,
      status: PropTypes.string,
      market: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        axe: PropTypes.string,
        actif: PropTypes.bool,
      }),
      wagonDamages: PropTypes.shape({
        damages: PropTypes.arrayOf(
          PropTypes.shape({
            damageTemplate: PropTypes.object,
          }),
        ),
      }),
      wagon: PropTypes.shape({
        registration: PropTypes.string,
        availabilityMode: PropTypes.oneOf(Object.keys(DamageReportEntity.WAGON_AVAILABILITY_MODE)),
        owner: PropTypes.shape({
          creationDate: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string,
          updateDate: PropTypes.string,
          version: PropTypes.number,
        }),
      }),
      company: PropTypes.oneOf(Object.keys(CompanyOptions)),
      repair: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        status: PropTypes.oneOf(Object.keys(RepairStatusLabels)).isRequired,
      }),
    };
  }

  static VALIDATION_STATUSES = {
    EMPTY: 'EMPTY',
    IN_PROGRESS: 'IN_PROGRESS',
    SUBMITTED: 'SUBMITTED',
    VALIDATED: 'VALIDATED',
    DISABLED: 'DISABLED',
    CLOSED: 'CLOSED',
  };

  static LABEL_MODEL_1_VALUE = 'ONE';
  static LABELS = [
    { label: '1', value: DamageReportEntity.LABEL_MODEL_1_VALUE },
    { label: '0', value: 'ZERO' },
    { label: '2', value: 'TWO' },
    { label: 'K', value: 'K' },
    { label: 'I', value: 'I' },
    { label: 'M', value: 'M' },
    { label: 'U', value: 'U' },
    { label: 'R1', value: 'R_ONE' },
    { label: 'IS', value: 'IS' },
    { label: 'IN', value: 'IN' },
  ];

  static RAIL_COMPANY_TYPES = {
    EF_CUU: 'EF CUU',
    EF: 'EF non CUU',
    ITE: 'ITE',
  };

  static CAUSE_TYPES = {
    WEAR: 'Usure',
    IMPACT_ON_OPERATION: 'Dommage causé par un choc en exploitation ferrovière',
    THIRD_PARTY: "Implication d'un tiers",
    MAINTENANCE: 'ECM (Entité en charge de la Maintenance)',
    UNKNOWN: 'Non identifiable',
  };

  static WAGON_AVAILABILITY_MODE = {
    BEFORE_UNLOADING: 'BEFORE_UNLOADING',
    AFTER_UNLOADING: 'AFTER_UNLOADING',
  };

  static getEmptyDamageReport = () => ({
    id: null,
    number: null,
    creationDate: null,
    status: DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS,
    validatedDate: null,
    market: null,
    wagon: {
      registration: '',
      loaded: false,
      owner: {
        id: null,
        email: null,
        name: null,
        vkm: null,
      },
      railCompany: {
        type: null,
        name: null,
      },
      availabilityMode: null,
    },
    train: {
      trainNumber: null,
      startDateTime: null,
      startStation: null,
      endStation: null,
    },
    labelling: {
      labels: [],
      railCompany: null,
      dateTime: null,
    },
    wagonDamages: {
      damages: [
        {
          damageTemplateId: '', // Forcing the back to create the damage
          comment: null,
        },
      ],
      comment: null,
      observation: {
        location: null,
        dateTime: null,
      },
    },
    cause: {
      type: null,
      responsibleThirdParty: {
        name: null,
        address: null,
        signature: null,
      },
    },
    newLabels: [],
    submittedBy: null,
    submittedDate: null,
    reportLocation: null,
    reportDateTime: null,
    attachments: [],
    company: null,
  });

  static createNewDamageReport = (wagon, owner, train, loaded, market, currentUserCompany, currentUserName) =>
    new DamageReportEntity({
      ...DamageReportEntity.getEmptyDamageReport(),
      id: uuid(),
      dirty: true,
      new: true,
      creationDate: new Date().toISOString(),
      createdBy: {
        name: currentUserName,
      },
      status:
        wagon || train
          ? DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS
          : DamageReportEntity.VALIDATION_STATUSES.EMPTY,
      labelling: {
        labels: wagon?.inProgressDamageReports ? wagon.inProgressDamageReports.flatMap((dr) => dr.labels) : [],
        railCompany: null,
        dateTime: null,
      },
      wagon: {
        registration: wagon ? wagon.registration : '',
        loaded: loaded || false,
        owner: owner
          ? {
              id: owner.id,
              email: owner.email,
              name: owner.name,
              vkm: owner.vkm,
            }
          : null,
        railCompany: wagon && wagon.railCompany ? wagon.railCompany : { type: null, name: null },
        availabilityMode: wagon ? wagon.availabilityMode : null,
      },
      train: {
        trainNumber: train ? train.trainNumber : '',
        startDateTime: train ? train.startDate : null,
        startStation: train ? train.startLocationLabel : '',
        endStation: train ? train.endLocationLabel : '',
      },
      market: market,
      company: currentUserCompany,
    });

  constructor(damageReport = DamageReportEntity.getEmptyDamageReport()) {
    super(damageReport);
    if (!this.status) {
      this.status = DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS;
    }
  }

  isStatusNew() {
    return this.status === DamageReportEntity.VALIDATION_STATUSES.EMPTY;
  }

  isStatusInProgress() {
    return this.status === DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS;
  }

  isStatusNewOrInProgress() {
    return this.isStatusNew() || this.isStatusInProgress();
  }

  isStatusSubmitted() {
    return this.status === DamageReportEntity.VALIDATION_STATUSES.SUBMITTED;
  }

  isStatusValidated() {
    return this.status === DamageReportEntity.VALIDATION_STATUSES.VALIDATED;
  }

  isAlreadyValidated() {
    return (
      this.validated &&
      this.status === DamageReportEntity.VALIDATION_STATUSES.VALIDATED &&
      moment(this.validatedDate) < now()
    );
  }

  isStatusDisabled() {
    return this.status === DamageReportEntity.VALIDATION_STATUSES.DISABLED;
  }

  setStatusNew() {
    this.status = DamageReportEntity.VALIDATION_STATUSES.EMPTY;
  }

  setStatusNotValidated() {
    this.status = DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS;
  }

  setStatusValidated() {
    this.status = DamageReportEntity.VALIDATION_STATUSES.VALIDATED;
  }

  getNumber() {
    return this.number;
  }

  getStatusText() {
    switch (this.status) {
      case DamageReportEntity.VALIDATION_STATUSES.EMPTY:
      case DamageReportEntity.VALIDATION_STATUSES.IN_PROGRESS:
        return 'En cours';
      case DamageReportEntity.VALIDATION_STATUSES.SUBMITTED:
        return 'À valider';
      case DamageReportEntity.VALIDATION_STATUSES.VALIDATED:
        return 'Validé';
      case DamageReportEntity.VALIDATION_STATUSES.DISABLED:
        return 'Désactivé';
      case DamageReportEntity.VALIDATION_STATUSES.CLOSED:
        // display as same as VALIDATED
        return 'Validé';
      default:
        throw new Error('Statut de PVCA inconnu');
    }
  }

  getDamagesCodes() {
    return this.wagonDamages?.damages.map((d) => d?.damageTemplateId).filter((c) => Boolean(c)) ?? [];
  }

  getNewLabels() {
    return this.newLabels ?? [];
  }

  hasOwner() {
    return Boolean(this.wagon && this.wagon.owner && this.wagon.owner.id);
  }

  shallowCopyForDirty(propertiesToAdd = {}) {
    const entity = new DamageReportEntity({ ...this }).unMarkForUpdatingBack().markDirty();
    if (entity.isStatusNew() && Object.keys(propertiesToAdd).length > 0) {
      entity.setStatusNotValidated();
    }
    return entity;
  }

  shallowCopyForDeletion() {
    return new DamageReportEntity({ ...this }).markForDeletion();
  }

  shallowCopyForUpdatingBack() {
    return new DamageReportEntity({ ...this }).markForUpdatingBack();
  }

  shallowCopyForUpdatingBackFailed() {
    // just make it dirty
    return this.shallowCopyForDirty();
  }

  setWithDefaultCompanyValue() {
    this.company = 'CTF';
    return this;
  }

  canCreateRepair(wagonTemplate) {
    // the damageReport should be validated and without repair associated, and the wagonTemplate don't have repair in progress
    return (
      this.status === DamageReportEntity.VALIDATION_STATUSES.VALIDATED &&
      !Boolean(this.repair || wagonTemplate?.inProgressRepairId)
    );
  }
}
