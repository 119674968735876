import { Engine, ENGINE_POSITIONS, isEngine, isWagon, Vehicle, Wagon, WagonHazardousMaterial } from './Vehicle';
import helpers from '../helpers/helpers';
import { WagonFormValues, WagonHazardousMaterialFormValues } from '../components/vehicles/edit/wagon/WagonForm';
import { parseToFloat } from '../redux-form/valueParser';
import wagonCache from '../templates/wagonCache';
import { wagonHasRedLabel } from '../../admin/vehicles-library/vehiclesLibraryUtils';

const formatEnginePosition = (engine: Engine): string | null => {
  const position = engine.enginePosition && ENGINE_POSITIONS[engine.enginePosition];
  return position?.shortLabel ?? null;
};

const wagonTotalWeight = (wagon: Wagon): number => {
  const totalWeight = (wagon.loadWeight || 0) + (wagon.tare || 0);
  return Math.ceil(totalWeight * 100) / 100;
};

export default {
  isStatusValidated(vehicle: Vehicle): boolean {
    return vehicle.status === 'VALIDATED';
  },
  formatRegistration(vehicle: Wagon): string {
    return vehicle.registration ? helpers.registrationFormat(vehicle.registration) : '';
  },
  formatEngineNumber(vehicle: Engine): string {
    return vehicle.number ? helpers.makeInsecable(vehicle.number) : '';
  },
  /**
   * Total weight in kg
   */
  totalWeight(vehicle: Vehicle): number | null {
    return isWagon(vehicle) ? wagonTotalWeight(vehicle) : vehicle.tare;
  },
  totalWeightLegend(vehicle: Vehicle): string {
    return isWagon(vehicle) ? 'masse totale' : 'tare';
  },
  calculatedEvp(wagon: Wagon): number | null {
    return wagon.length && (wagon.nbUtiVehicle || wagon.nbUtiOther || wagon.nbUtiTrailer || wagon.nbUtiContainer)
      ? wagon.length / 6.1
      : null;
  },
  isFilled(vehicle: Vehicle): boolean {
    return isWagon(vehicle) && (vehicle.loadWeight ?? 0) > 0;
  },
  isChargeD(vehicle: Vehicle): boolean {
    return isWagon(vehicle) && vehicle.charge === 'D';
  },
  isRollingGoods(vehicle: Vehicle) {
    return isEngine(vehicle) && Boolean(vehicle.rollingGoods);
  },
  isAteOrGauge(vehicle: Vehicle): boolean {
    return isWagon(vehicle) && (Boolean(vehicle.ateFileNumber) || vehicle.gbGauge);
  },
  isRat(vehicle: Vehicle): boolean {
    return isWagon(vehicle) && Boolean(vehicle.rat);
  },
  getHazardousMaterials(vehicle: Vehicle): readonly WagonHazardousMaterial[] {
    return isWagon(vehicle) ? vehicle.hazardousMaterials : [];
  },
  formatPosition(vehicle: Vehicle): number | string | null {
    return isWagon(vehicle) ? vehicle.index : formatEnginePosition(vehicle as Engine);
  },
  computeWagonHazmatsLoadWeight(wagon: Wagon | WagonFormValues): number {
    const hazardousMaterials =
      (wagon.hazardousMaterials as (WagonHazardousMaterial | WagonHazardousMaterialFormValues)[]) ?? [];
    return hazardousMaterials.reduce((totalWeight, hazmat) => {
      const parsedWeight = parseToFloat(hazmat.weight ?? 0) || 0;
      return totalWeight + parsedWeight;
    }, 0);
  },
  isTractionEngine(vehicle: Vehicle): boolean {
    return isEngine(vehicle) && vehicle.enginePosition !== null && ['UM', 'US', 'DT'].includes(vehicle.enginePosition);
  },
  isVehicleEngine(vehicle: Vehicle): boolean {
    return isEngine(vehicle) && vehicle.enginePosition === 'VEHICLE';
  },
  isPushEngine(vehicle: Vehicle): boolean {
    return isEngine(vehicle) && vehicle.enginePosition === 'P';
  },
  linkToVehicle(vehicle: Vehicle): string {
    return `/trains/steps/${vehicle.stepId}/vehicles/${vehicle.id}`;
  },
  async doesVehicleHasErrorLabel(vehicle: Vehicle) {
    if (vehicle.registration) {
      return wagonCache.findItemById(vehicle.registration).then((template) => wagonHasRedLabel(template));
    }
    return false;
  },
};
