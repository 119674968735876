import offlineUtils from '../commons/offline/offlineUtils';
import { startToast } from '../commons/components/toast/toastDucks';
import { updateDamageReportsAfterPersist } from '../commons/cache/cacheSynchronizer';
import { loadDamageReportSuccess } from '../damage-reports/damage-report/damageReportLoadActions';
import apiHelper from '../api/apiHelper';
import { getDamageReports, getPersist } from '../commons/selectors/selectors';
import debugFactory from 'debug';

const debug = debugFactory('damageReportsPersistActions');

export const DAMAGE_REPORTS_PERSIST_SCHEDULE = 'DAMAGE_REPORTS_PERSIST_SCHEDULE';
export const DAMAGE_REPORTS_PERSIST_START = 'DAMAGE_REPORTS_PERSIST_START';
export const DAMAGE_REPORTS_PERSIST_SUCCESS = 'DAMAGE_REPORTS_PERSIST_SUCCESS';
export const DAMAGE_REPORTS_PERSIST_FAILURE = 'DAMAGE_REPORTS_PERSIST_FAILURE';

export const damageReportsPersistFailure = (damageReports = []) => ({
  type: DAMAGE_REPORTS_PERSIST_FAILURE,
  payload: {
    damageReportsUpdated: damageReports.map((damageReport) => damageReport.shallowCopyForUpdatingBackFailed()),
  },
});

// actions
export const damageReportsPersistSuccess =
  (damageReportsInRequest, damageReportsInResponse) => (dispatch, getState) => {
    if (damageReportsInRequest.length > 0) {
      const cache = updateDamageReportsAfterPersist(
        getState().damageReports.cache,
        damageReportsInRequest,
        damageReportsInResponse,
      );
      dispatch({
        type: DAMAGE_REPORTS_PERSIST_SUCCESS,
        payload: {
          cache,
        },
      });
      // show a message if the damage report is already validated and the modification is rejected.
      const alreadyValidatedButModificationRejectedByServer = damageReportsInResponse
        .filter((damageReport) => damageReport.invalid)
        .map((damageReport) => cache.loadByKeyFromCache(damageReport.id))
        .filter((damageReport) => damageReport.isAlreadyValidated());

      if (alreadyValidatedButModificationRejectedByServer.length) {
        dispatch(startToast({ text: 'Veuillez vérifier votre saisie.', className: 'error' }));
      } else {
        // Show a message if the damage report validation was rejected.
        const invalidDamageReports = damageReportsInResponse
          .filter((damageReport) => damageReport.invalid)
          .map((damageReport) => cache.loadByKeyFromCache(damageReport.id))
          .filter((damageReport) => damageReport.isStatusSubmitted() || damageReport.isStatusValidated());
        if (invalidDamageReports.length) {
          const message =
            invalidDamageReports.length === 1
              ? `Le PVCA ${invalidDamageReports[0].id} n'a pas été soumis/validé car il a été modifié 
        sur un autre appareil.`
              : `Les PVCA ${invalidDamageReports
                  .map((invalidDamageReport) => invalidDamageReport.id)
                  .join(', ')} n'ont pas été soumis/validés 
          car ils ont été modifiés sur un autre appareil.`;
          dispatch(startToast({ text: message, className: 'error' }));
        }
      }
      const currentDamageReport = getState().damageReport.data;
      if (currentDamageReport.id) {
        const inResponse = damageReportsInResponse.some((d) => d.id === currentDamageReport.id);
        if (inResponse) {
          const inRequestReport = damageReportsInRequest.find((d) => d.id === currentDamageReport.id);
          if (inRequestReport && !inRequestReport.pendingForDeletion) {
            dispatch(loadDamageReportSuccess(cache.loadByKeyFromCache(currentDamageReport.id)));
          }
        }
      }
    }
  };

export const syncDirtyDamageReports = () => (dispatch, getState) => {
  if (offlineUtils.isOnline()) {
    const state = getState();
    if (getPersist(state).savingDamageReports) {
      debug('Skipping damage reports sync because one is ongoing');
      return;
    }

    const damageReportsInStore = getDamageReports(state);
    const damageReportsToSync = Array.from(damageReportsInStore.cache.cache.values()).filter((damageReport) =>
      damageReport.isSynchable(),
    );

    if (damageReportsToSync.length > 0) {
      debug(`Send ${damageReportsToSync.length} damage reports to synchronize`);
      dispatch(damageReportsPersistStart(damageReportsToSync));
    }
  }
};

export const damageReportsPersistSchedule =
  (damageReports = []) =>
  (dispatch) => {
    dispatch({
      type: DAMAGE_REPORTS_PERSIST_SCHEDULE,
      payload: {
        damageReportsUpdated: damageReports.map((damageReport) => damageReport.shallowCopyForUpdatingBackFailed()),
      },
    });
    dispatch(syncDirtyDamageReports());
    return null;
  };

export const damageReportsPersistStart =
  (damageReports = []) =>
  async (dispatch) => {
    const damageReportsUpdated = damageReports.map((damageReport) => damageReport.shallowCopyForUpdatingBack());
    dispatch({
      type: DAMAGE_REPORTS_PERSIST_START,
      payload: { damageReportsUpdated },
    });

    if (!offlineUtils.isOnline()) {
      dispatch(damageReportsPersistFailure(damageReportsUpdated));
      return null;
    }

    try {
      const persistedDamageReports = await apiHelper.post(
        '/api/damageReports',
        damageReportsUpdated.map((dr) => ({ ...dr, type: 'internal' })),
      );
      dispatch(damageReportsPersistSuccess(damageReportsUpdated, persistedDamageReports));
      dispatch(syncDirtyDamageReports());
    } catch {
      dispatch(damageReportsPersistFailure(damageReportsUpdated));
      if (offlineUtils.isOnline()) {
        dispatch(
          startToast({
            text: "Le PVCA n'a pas pu être enregistré sur le serveur, veuillez vérifier le format",
            className: 'error',
          }),
        );
        dispatch(syncDirtyDamageReports());
      }
    }
  };
