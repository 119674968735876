import React from 'react';
import RepairsTableHeader from './RepairsTableHeader';
import './RepairsTableStyles.scss';
import GlobalError from '../../commons/components/GlobalError';
import ScrollableTableBody from '../../commons/components/table/ScrollableTableBody';
import RepairRow from './RepairRow';
import { RepairListView } from '../repairTypes';
import { Sort } from '../../commons/model/sort';

type Props = {
  error: boolean;
  loading: boolean;
  repairs: RepairListView[];
  sortDefinition: Sort<RepairListView>;
  updateSortDefinition: (newSort: Sort<RepairListView>) => void;
  onTableRowClick: (repairId: string) => void;
};
const RepairsTable = ({ error, loading, repairs, sortDefinition, updateSortDefinition, onTableRowClick }: Props) => {
  if (error) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération des réparations.
      </GlobalError>
    );
  }

  if (repairs.length === 0) {
    return (
      <div className="maincontent">{!loading && <span>Aucune réparation ne correspond à la recherche.</span>}</div>
    );
  }

  return (
    <div className="table table-repairs">
      <RepairsTableHeader sortDefinition={sortDefinition} updateSort={updateSortDefinition} />
      <ScrollableTableBody>
        {repairs.map((repair) => () => <RepairRow key={repair.id} repair={repair} onRowClick={onTableRowClick} />)}
      </ScrollableTableBody>
    </div>
  );
};

export default RepairsTable;
