import { DamageReportLabelKey } from '../../../commons/model/DamageReport';
import classNames from 'classnames';
import React from 'react';
import { WagonTemplateDamageReport, WagonTemplateDamageTemplate } from '../../../commons/model/templates';
import DamageLabel from '../DamageLabel';
import './wagonDamageReportDetailsStyles.scss';
import helpers from '../../../commons/helpers/helpers';

type WagonDamageReportDetailsProps = {
  damageReport: WagonTemplateDamageReport | undefined;
};
const WagonDamageReportDetails = ({ damageReport }: WagonDamageReportDetailsProps) => {
  if (!damageReport) {
    return <></>;
  }

  return (
    <tr>
      <td className="damage-report-number">
        {damageReport.number ? (
          <a href={`/damage-reports/${damageReport.id}`} style={{ fontWeight: 'bold' }}>
            N°{damageReport.number}
          </a>
        ) : (
          <span className="external-damage-report">Externe</span>
        )}
      </td>
      <LabellingComponent newLabels={damageReport.labels} />
      <DamagesComponent damageTemplates={damageReport.damageTemplates} />
      <td className="damage-report-validation-date">
        {helpers.dateTimeFormat(damageReport.validatedDate, 'D MMM YYYY')}
      </td>
    </tr>
  );
};

const LabellingComponent = ({ newLabels }: { newLabels: DamageReportLabelKey[] }) => {
  return (
    <td>
      {newLabels.map((label, i) => (
        <span key={label + i}>
          <DamageLabel labelCode={label} />
          {i < newLabels.length - 1 && ', '}
        </span>
      ))}
    </td>
  );
};

const DamagesComponent = ({ damageTemplates }: { damageTemplates: WagonTemplateDamageTemplate[] }) => {
  const isDamageCritical = (dt: WagonTemplateDamageTemplate) => {
    return dt.severity === 5;
  };

  return (
    <td>
      {damageTemplates.map((dt, index) => (
        <span key={dt.id + index}>
          <span className={classNames({ severe: isDamageCritical(dt) })}>{dt.id}</span>
          {index < damageTemplates.length - 1 && ', '}
        </span>
      ))}
    </td>
  );
};

export default WagonDamageReportDetails;
