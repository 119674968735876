import { Autocompleter } from './Autocompleter';
import { DamageReportSimpleView } from '../../repairs/repairTypes';

class DamageReportInRepairFormAutocompleter implements Autocompleter<DamageReportSimpleView> {
  options;

  constructor(options: DamageReportSimpleView[]) {
    this.options = options;
  }

  autocomplete = async (inputValue: string | null): Promise<DamageReportSimpleView[]> => {
    if (!inputValue || inputValue.length < 2) {
      return [];
    }

    return this.options.filter((dr: DamageReportSimpleView) => dr.damageReportNumber.toString().startsWith(inputValue));
  };

  autocompleteWithFilter = (filter: (dr: DamageReportSimpleView) => boolean) => async (inputValue: string | null) => {
    return (await this.autocomplete(inputValue)).filter(filter);
  };

  formatItem = (dr: DamageReportSimpleView) => `${dr.damageReportNumber}`;
}

export default DamageReportInRepairFormAutocompleter;
