import React from 'react';
import { WagonTemplate } from '../../../model/templates';
import DamageReportEntity from '../../../entity/DamageReportEntity';
import './wagonLabelsStyles.scss';

type Props = {
  wagonTemplate: WagonTemplate;
};

const WagonTemplateLabels = ({ wagonTemplate }: Props) => {
  const labels = [...new Set(wagonTemplate.inProgressDamageReports?.flatMap((report) => report.labels))] ?? [];

  const sortedWagonExistingLabels = labels
    .sort(
      (a, b) =>
        DamageReportEntity.LABELS.findIndex((value) => value.value === a) -
        DamageReportEntity.LABELS.findIndex((value) => value.value === b),
    )
    .map((label) => DamageReportEntity.LABELS.find((value) => value.value === label));

  return (
    <div className={`list-vehicle-labels`}>
      {sortedWagonExistingLabels.map((l) => (
        <div className={`wagon-label-${l?.value}`} key={`wagon-${wagonTemplate.registration}-${l?.value}`}>
          {l?.label}
        </div>
      ))}
    </div>
  );
};

export default WagonTemplateLabels;
