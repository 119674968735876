import { DamageReportSimpleView, InitRepairFormData, RepairFormValues } from './repairTypes';
import moment from 'moment/moment';
import { isFieldEmpty, isRegistration } from '../commons/redux-form/valueValidator';
import apiHelper from '../api/apiHelper';
import { ValidationErrors } from '../commons/validation/validationUtils';
import { FormErrors } from 'redux-form';
import wagonCache from '../commons/templates/wagonCache';

export const repairFormValuesToCommand = (repairFormValues: RepairFormValues) => {
  return {
    interventionRequestDate: moment(repairFormValues.interventionRequestDate)
      .startOf('day')
      .local()
      .format('yyyy-MM-DD'),
    repairStartDate: repairFormValues.repairStartDate
      ? moment(repairFormValues.repairStartDate).startOf('day').local().format('yyyy-MM-DD')
      : null,
    repairEndDate: repairFormValues.repairEndDate
      ? moment(repairFormValues.repairEndDate).startOf('day').local().format('yyyy-MM-DD')
      : null,
    wagonTemplateId: repairFormValues.wagonTemplate?.id,
    damageReportId: repairFormValues.damageReport?.id,
    marketId: repairFormValues.market?.id,
    workshop: repairFormValues.workshop,
    accidentNumber: repairFormValues.accidentNumber,
    comment: repairFormValues.comment,
  };
};

export const repairDetailsToFormValues = ({
  repairDetails,
  initialWagonTemplate,
  initialDamageReport,
}: InitRepairFormData): RepairFormValues => {
  if (repairDetails === null) {
    return {
      id: null,
      status: 'IN_PROGRESS',
      repairNumber: null,
      wagonTemplate: initialWagonTemplate ?? null,
      damageReport: initialDamageReport ?? null,
      interventionRequestDate: moment(),
      repairStartDate: null,
      repairEndDate: null,
      market: null,
      workshop: null,
      accidentNumber: null,
      comment: null,
    };
  }
  return {
    id: repairDetails.id,
    status: repairDetails.status,
    repairNumber: repairDetails.number,
    wagonTemplate: repairDetails.wagonTemplate,
    damageReport: repairDetails.damageReport,
    interventionRequestDate: moment(repairDetails.interventionRequestDate),
    repairStartDate: repairDetails.repairStartDate ? moment(repairDetails.repairStartDate) : null,
    repairEndDate: repairDetails.repairEndDate ? moment(repairDetails.repairEndDate) : null,
    market: repairDetails.market,
    workshop: repairDetails.workshop,
    accidentNumber: repairDetails.accidentNumber,
    comment: repairDetails.comment,
  };
};

export const validateWagonTemplate = (
  repair: RepairFormValues,
): {
  wagonTemplate: ValidationErrors<'registration'>;
} => {
  if (isFieldEmpty(repair.wagonTemplate?.registration)) {
    return { wagonTemplate: { registration: 'Immatriculation obligatoire' } };
  }

  if (repair.wagonTemplate?.registration && !repair.wagonTemplate?.id) {
    return { wagonTemplate: { registration: 'Veuillez sélectionner un wagon' } };
  }

  if (
    repair.wagonTemplate &&
    repair.wagonTemplate?.registration &&
    !isRegistration(repair.wagonTemplate?.registration)
  ) {
    return { wagonTemplate: { registration: "L'immatriculation n'est pas conforme" } };
  }

  // the wagonRegistration in selected PVCA should be the same of wagonTemplate selected
  if (
    repair.wagonTemplate?.registration &&
    repair.damageReport?.damageReportNumber &&
    repair.damageReport?.wagonRegistration &&
    repair.wagonTemplate.registration !== repair.damageReport?.wagonRegistration
  ) {
    return { wagonTemplate: { registration: "Le wagon dans le PVCA ne correspond pas à l'immatriculation saisie" } };
  }

  return { wagonTemplate: {} };
};

export const validateRepairDamageReport = (
  repair: RepairFormValues,
): {
  damageReport: ValidationErrors<'damageReportNumber'>;
} => {
  if (repair.damageReport?.damageReportNumber && !repair.damageReport?.id) {
    return {
      damageReport: {
        damageReportNumber: 'Veuillez sélectionner un PVCA',
      },
    };
  }
  return { damageReport: {} };
};
export const validateMarket = (repair: RepairFormValues): { market: ValidationErrors<'name'> } => {
  if (!repair.market?.name) {
    return { market: { name: 'Marché obligatoire' } };
  }

  return { market: {} };
};
export const validateWorkshop = (repair: RepairFormValues): { workshop: ValidationErrors<'name'> } => {
  if (!repair.workshop?.name) {
    return { workshop: { name: 'Atelier obligatoire' } };
  }

  return { workshop: {} };
};
export const validateInterventionRequestDate = (
  repair: RepairFormValues,
): ValidationErrors<'interventionRequestDate'> => {
  if (!repair.interventionRequestDate || !moment.isMoment(repair.interventionRequestDate)) {
    return { interventionRequestDate: "Date de demande d'intervention obligatoire" };
  }

  if (repair.interventionRequestDate.isAfter(moment())) {
    return { interventionRequestDate: "La date de demande d'intervention doit être antérieure ou égale à aujourd'hui" };
  }
  return {};
};
export const validateRepairStartDate = (repair: RepairFormValues): ValidationErrors<'repairStartDate'> => {
  if (repair.repairEndDate && !repair.repairStartDate) {
    return { repairStartDate: "Veuillez saisir la date d'entrée" };
  }

  if (repair.repairStartDate && repair.repairStartDate.isAfter(moment())) {
    return { repairStartDate: "La date d'entrée doit être antérieure ou égale à aujourd'hui" };
  }

  return {};
};

export const validateRepairEndDate = (repair: RepairFormValues): ValidationErrors<'repairEndDate'> => {
  if (repair.repairEndDate && repair.repairEndDate.isAfter(moment())) {
    return { repairEndDate: "La date de sortie doit être antérieure ou égale à aujourd'hui" };
  }

  if (
    repair.repairStartDate &&
    repair.repairEndDate &&
    repair.repairEndDate.startOf('day').isBefore(repair.repairStartDate.startOf('day'))
  ) {
    return { repairEndDate: "La date de sortie doit être antérieure ou égale à la date d'entrée" };
  }

  return {};
};

export const validateComment = (repair: RepairFormValues): ValidationErrors<'comment'> => {
  if (repair.comment && repair.comment.length > 1000) {
    return { comment: 'Maximum 1000 caractères.' };
  }

  return {};
};

export const validateRepairDetailsForm = (repair: RepairFormValues): FormErrors<RepairFormValues> => {
  const errors: FormErrors<RepairFormValues> = {
    ...validateInterventionRequestDate(repair),
    ...validateRepairStartDate(repair),
    ...validateRepairEndDate(repair),
    ...validateComment(repair),
  };

  const damageReportErrors = validateRepairDamageReport(repair);
  Object.assign(errors, damageReportErrors);

  const wagonTemplateErrors = validateWagonTemplate(repair);
  Object.assign(errors, wagonTemplateErrors);

  const workshopErrors = validateWorkshop(repair);
  Object.assign(errors, workshopErrors);

  const marketErrors = validateMarket(repair);
  Object.assign(errors, marketErrors);

  return errors;
};

const asyncValidateDamageReportNumber = async (repair: RepairFormValues) => {
  const { id, damageReport } = repair;
  const errors: ValidationErrors<'damageReportNumber'> = { damageReportNumber: undefined };

  // if the repair is created, no need to validate again since the field are readonly
  if (!id && damageReport?.damageReportNumber) {
    const damageReportOptions: DamageReportSimpleView[] = await apiHelper.get('api/repairs/damage-report-options');

    if (damageReport.id && damageReport.damageReportNumber) {
      const damageReportOptionFound = damageReportOptions.find((dr) => dr.id === damageReport.id);
      if (damageReportOptionFound?.damageReportNumber !== damageReport.damageReportNumber) {
        // handle the case where the input value has been modified after the select
        errors.damageReportNumber = 'Veuillez sélectionner un PVCA existant';
      }
    }
  }
  return { damageReport: errors };
};

const asyncValidateWagonTemplate = async (repair: RepairFormValues) => {
  const { id, wagonTemplate, damageReport } = repair;
  const errors: ValidationErrors<'registration'> = { registration: undefined };

  // if the repair is created, no need to validate again since the field are readonly
  if (
    !id &&
    wagonTemplate?.registration &&
    damageReport?.wagonRegistration &&
    wagonTemplate?.registration !== damageReport?.wagonRegistration
  ) {
    errors.registration = "Le wagon dans le PVCA ne correspond pas à l'immatriculation saisie";
  }

  if (wagonTemplate?.registration && wagonTemplate.id !== null) {
    const wagonTemplateFound = await wagonCache.findItemById(wagonTemplate?.registration);
    if (!wagonTemplateFound || wagonTemplateFound.id !== wagonTemplate.id) {
      errors.registration = 'Veuillez sélectionner un wagon';
    }
  }

  return { wagonTemplate: errors };
};

const asyncValidateComment = (repair: RepairFormValues) => {
  const { comment } = repair;

  if (comment && comment.length > 1000) {
    return { comment: 'Maximum 1000 caractères.' };
  }
  return undefined;
};

export const asyncValidateRepairDetailsForm = async (repair: RepairFormValues) => {
  const errors = {
    ...(await asyncValidateDamageReportNumber(repair)),
    ...(await asyncValidateWagonTemplate(repair)),
    ...asyncValidateComment(repair),
  };

  if (errors.damageReport?.damageReportNumber || errors.wagonTemplate?.registration) {
    throw errors;
  }
};
