import React, { useEffect, useState } from 'react';

import { DAMAGE_REPORT_VALIDATE, DAMAGE_REPORT_WRITE, REPAIR_WRITE } from '../../../commons/security/userRoles';
import { askForValidation, deactivateDamageReport, deleteDamageReport, validate } from '../damageReportDucks';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import classNames from 'classnames';
import { useAppSelector } from '../../../commons/store/hooks';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import { createRepair } from '../../../repairs/RepairDucks';
import { useDispatch } from 'react-redux';
import { browserHistory } from '../../../commons/routes/routerHistoryConfig';
import wagonCache from '../../../commons/templates/wagonCache';
import DamageReportEntity from '../../../commons/entity/DamageReportEntity';

const DamageReportActions = ({ roles, damageReport, offline, openConfirmDialogStart }) => {
  const { isMobile } = useViewportWidth();
  const dispatch = useDispatch();
  const currentUserCompany = useAppSelector(selectSecurityContext).company;
  const canEdit = roles.includes(DAMAGE_REPORT_WRITE) && damageReport.company === currentUserCompany;
  const canValidate = roles.includes(DAMAGE_REPORT_VALIDATE) && damageReport.company === currentUserCompany;
  const canCreateRepair = roles.includes(REPAIR_WRITE);

  const [wagonTemplate, setWagonTemplate] = useState();

  useEffect(() => {
    if (damageReport.status === DamageReportEntity.VALIDATION_STATUSES.VALIDATED) {
      async function setData() {
        const wagonTemplate = await wagonCache.findItemById(damageReport.wagon.registration);
        setWagonTemplate(wagonTemplate);
      }
      setData();
    }
  }, [damageReport]);

  const openDialogAction =
    (title, actionText, action, actionClass = 'success') =>
    () => {
      openConfirmDialogStart({ title, actionText, action, actionClass });
    };

  const askAction = {
    buttonText: 'Demander la validation',
    onClick: openDialogAction(
      'Vous êtes sur le point de demander la validation du PVCA. Confirmez-vous ?',
      'Demander la validation',
      () => askForValidation(),
    ),
    classes: 'btn btn-success',
  };

  const validateAction = {
    buttonText: 'Valider',
    onClick: openDialogAction('Vous êtes sur le point de valider le PVCA. Confirmez-vous ?', 'Valider', () =>
      validate(),
    ),
    classes: 'btn btn-success',
  };

  const deleteAction = {
    buttonText: '',
    onClick: openDialogAction(
      'Vous êtes sur le point de supprimer le PVCA. Confirmez-vous ?',
      'Supprimer',
      () => deleteDamageReport(damageReport),
      'danger',
    ),
    classes: 'btn btn-round remove',
  };

  const deactivateAction = {
    buttonText: 'Désactiver',
    onClick: openDialogAction(
      'Vous êtes sur le point de désactiver le PVCA. Confirmez-vous ?',
      'Désactiver',
      () => deactivateDamageReport(damageReport),
      'danger',
    ),
    classes: 'btn btn-deactivate btn-icon',
  };

  const createRepairAction = {
    buttonText: 'Créer une réparation',
    onClick: async () => {
      browserHistory.push('/repairs');
      dispatch(
        createRepair({
          repairDetails: null,
          initialDamageReport: {
            id: damageReport.id,
            damageReportNumber: damageReport.number,
            wagonRegistration: damageReport.wagon.registration,
          },
          initialWagonTemplate: wagonTemplate,
        }),
      );
    },
    classes: 'btn btn-new-repair btn-icon',
  };

  const actions = [];

  if (damageReport.isStatusNewOrInProgress()) {
    if (canEdit && !canValidate) {
      actions.push(askAction);
      actions.push(deleteAction);
    }
    if (canEdit && canValidate) {
      actions.push(validateAction);
      actions.push(deleteAction);
    }
  }
  if (damageReport.isStatusSubmitted() && canValidate) {
    actions.push(validateAction);
    actions.push(deleteAction);
  }
  if (damageReport.isStatusValidated() && canValidate) {
    actions.push(deactivateAction);
  }
  if (damageReport.canCreateRepair(wagonTemplate) && canCreateRepair) {
    actions.push(createRepairAction);
  }

  return (
    <div className="damage-report-header-actions">
      {actions.map((action) => (
        <button key={action.buttonText} className={action.classes} onClick={action.onClick} type="button">
          {action.buttonText}
        </button>
      ))}
      {!offline && (
        <a
          className={classNames('btn pdf', isMobile ? 'btn-round' : 'btn-link btn-icon')}
          href={`/api/damageReports/print/${damageReport.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Télécharger le PVCA
        </a>
      )}
    </div>
  );
};

export default DamageReportActions;
