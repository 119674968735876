import { WagonTemplate, WagonTemplateDamageTemplate } from '../../../model/templates';
import './wagonTemplateDamagesStyles.scss';
import classNames from 'classnames';
import React from 'react';

type Props = {
  wagonTemplate: WagonTemplate;
};

const WagonTemplateDamages = ({ wagonTemplate }: Props) => {
  const damageTemplates =
    [
      ...new Map(
        wagonTemplate.inProgressDamageReports?.flatMap((report) => report.damageTemplates).map((dt) => [dt.id, dt]), // Use map to avoid replicate
      ).values(),
    ] ?? [];

  const sortedDamageTemplates = damageTemplates.sort((a, b) => a.id.localeCompare(b.id));

  const isDamageCritical = (dt: WagonTemplateDamageTemplate) => {
    return dt.severity === 5;
  };

  return (
    <div className={`vehicle-damages`}>
      {sortedDamageTemplates.map((d, index) => (
        <div className={`wagon-damage-${d.id}`} key={`wagon-${wagonTemplate.registration}-${d.id}`}>
          <span className={classNames({ severe: isDamageCritical(d) })}>
            {d.id}
            {index < sortedDamageTemplates.length - 1 && ', '}
          </span>
        </div>
      ))}
    </div>
  );
};

export default WagonTemplateDamages;
