import AbstractTemplateCache from './AbstractTemplateCache';
import { Workshop } from '../model/templates';
import { Tx } from './AbstractDbCache';
import { useEffect, useState } from 'react';

class WorkshopCache extends AbstractTemplateCache<'workshop', Workshop> {
  protected getStoreName(): 'workshop' {
    return 'workshop';
  }

  protected validateInput(inputValue: string): boolean {
    return inputValue.length >= 1;
  }

  protected findTemplates(inputValue: string, tx: Tx<'workshop'>) {
    const normalizedInputValue = this.normalizeInput(inputValue);
    return this.findItemsByMatcher(tx, (workshop) => workshop.name.toUpperCase().includes(normalizedInputValue));
  }

  public findByName(name: string): Promise<Workshop | undefined> {
    return this.findItemByIndex(name, 'by-name');
  }

  public formatItem(item: Workshop): string {
    return item.name;
  }
}

const workshopCache = new WorkshopCache();

async function sortByName(itemsPromise: Promise<Workshop[]>) {
  const items = await itemsPromise;
  items.sort((workshop1, workshop2) => workshop1.name.localeCompare(workshop2.name));
  return items;
}

export const useWorkshops = () => {
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  useEffect(() => {
    (async () => {
      setWorkshops(await sortByName(workshopCache.findAllItems()));
    })();
  }, []);
  return {
    workshops,
  };
};
export default workshopCache;
