import { WagonTemplate } from '../model/templates';
import { Wagon } from '../model/Vehicle';

export const updateWagonTemplateWithCompositionWagon = (
  wagonTemplate: WagonTemplate | undefined,
  compositionWagon: Wagon,
): WagonTemplate => {
  const { registration, length, tare, nbAxles, owner, doubleWagon } = compositionWagon;
  return {
    ...(wagonTemplate ?? {
      status: 'TO_REVIEW',
      manager: null,
    }),
    registration: registration!,
    length: length!,
    tare: tare!,
    nbAxles: nbAxles!,
    ownerId: owner!.id,
    ownerName: owner!.name,
    doubleWagon,
    inProgressRepairId: wagonTemplate?.inProgressRepairId ?? null,
    inProgressDamageReports: wagonTemplate?.inProgressDamageReports ?? [],
    wagonGeneralStatus: wagonTemplate?.wagonGeneralStatus ?? 'OPERATIONAL',
  };
};

export const updateWagonTemplateWithWagonTemplateCommand = (
  wagonTemplate: WagonTemplate | undefined,
  command: WagonTemplate,
): WagonTemplate => {
  const {
    id,
    registration,
    length,
    tare,
    nbAxles,
    ownerId,
    ownerName,
    doubleWagon,
    status,
    manager,
    inProgressDamageReports,
    inProgressRepairId,
    wagonGeneralStatus,
  } = command;
  const existingWagonTemplate = {
    ...wagonTemplate,
  };
  existingWagonTemplate.id = existingWagonTemplate.id ?? id;
  return {
    ...existingWagonTemplate,
    registration,
    length,
    tare,
    nbAxles,
    ownerId,
    ownerName,
    doubleWagon,
    status,
    manager,
    inProgressRepairId,
    inProgressDamageReports,
    wagonGeneralStatus,
  };
};
