import { DamageReportSimpleView, InitRepairFormData, RepairDetailsView, RepairFormValues } from './repairTypes';
import { AppDispatch } from '../commons/store/store';
import apiHelper from '../api/apiHelper';
import { startToast } from '../commons/components/toast/toastDucks';
import { repairDetailsToFormValues, repairFormValuesToCommand } from './repairUtils';
import { reset, SubmissionError } from 'redux-form';
import ApiError from '../api/ApiError';
import { AnyAction } from 'redux';
import { hideOverlay, showOverlay } from '../commons/components/overlay/overlayDucks';
import { requestGetRepairs } from './RepairsDucks';

// ACTIONS
const LOAD_REPAIR = 'LOAD_REPAIR';
const CREATE_REPAIR = 'CREATE_REPAIR';
const PERSIST_REPAIR_START = 'PERSIST_REPAIR_START';
const PERSIST_REPAIR_SUCCESS = 'PERSIST_REPAIR_SUCCESS';
const PERSIST_REPAIR_ERROR = 'PERSIST_REPAIR_ERROR';

export type RepairState = {
  data: RepairFormValues | null;
  damageReportOptions: DamageReportSimpleView[];
  loading: boolean;
  /**
   * Error status code, or 0 for no error
   */
  error: boolean;
};

const initialState: RepairState = {
  data: null,
  damageReportOptions: [],
  loading: false,
  error: false,
};

export default (state: RepairState = initialState, action: AnyAction): RepairState => {
  switch (action.type) {
    case LOAD_REPAIR:
      return {
        ...action.payload,
        loading: false,
        error: false,
      };
    case CREATE_REPAIR:
      return {
        ...action.payload,
        loading: false,
        error: false,
      };
    case PERSIST_REPAIR_START:
      return {
        ...state,
        error: false,
      };
    case PERSIST_REPAIR_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

// ACTIONS
export const loadRepair = (repairId: string) => async (dispatch: AppDispatch) => {
  const repair: RepairDetailsView = await apiHelper.get(`/api/repairs/${repairId}`);
  const damageReportOptions: DamageReportSimpleView[] = await apiHelper.get('api/repairs/damage-report-options');
  dispatch({
    type: LOAD_REPAIR,
    payload: {
      data: repairDetailsToFormValues({ repairDetails: repair }),
      damageReportOptions,
    },
  });
  dispatch(reset('repairForm')); // If we load the same repair twice, the form is not reinitialized.
  dispatch(showOverlay('repair-details'));
};

export const createRepair = (initRepairFormData: InitRepairFormData) => async (dispatch: AppDispatch) => {
  const damageReportOptions: DamageReportSimpleView[] = await apiHelper.get('api/repairs/damage-report-options');
  dispatch({
    type: CREATE_REPAIR,
    payload: { data: repairDetailsToFormValues(initRepairFormData), damageReportOptions },
  });
  dispatch(reset('repairForm')); // If we load the same repair twice, the form is not reinitialized.
  dispatch(showOverlay('repair-details'));
};

const doPersist = async (persistRequest: Promise<void>, actionVerb: string, dispatch: AppDispatch) => {
  dispatch({ type: PERSIST_REPAIR_START });

  try {
    await persistRequest;
    dispatch({ type: PERSIST_REPAIR_SUCCESS });
    dispatch(requestGetRepairs());
    dispatch(startToast({ text: `La réparation a été ${actionVerb} avec succès.`, className: 'success' }));
    dispatch(hideOverlay());
  } catch (err) {
    dispatch({ type: PERSIST_REPAIR_ERROR });

    if (err instanceof ApiError && err.httpStatus === 400) {
      if (err.errors) {
        var frontErrors = { ...err.errors };
        // rename the error's fieldName
        if (Object.prototype.hasOwnProperty.call(err.errors, 'wagonTemplateId')) {
          frontErrors.wagonTemplate = {
            registration: err.errors.wagonTemplateId,
          };
        }
        if (Object.prototype.hasOwnProperty.call(err.errors, 'damageReportId')) {
          frontErrors.damageReport = {
            damageReportNumber: err.errors.damageReportId,
          };
        }

        throw new SubmissionError(frontErrors);
      } else {
        dispatch(
          startToast({
            text: `Erreur : la réparation n'a pas été ${actionVerb}.\n${err.message ?? ''}`,
            className: 'error',
          }),
        );
      }
    } else {
      dispatch(startToast({ text: `Erreur : la réparation n'a pas été ${actionVerb}.`, className: 'error' }));
    }
  }
};

export const persistRepairStart =
  (repair: RepairFormValues, id: string | null | undefined) => (dispatch: AppDispatch) => {
    const params = repairFormValuesToCommand(repair);
    const persistRequest = id ? apiHelper.put(`/api/repairs/${id}`, params) : apiHelper.post('/api/repairs', params);
    const actionVerb = id ? 'mise à jour' : 'créée';
    return doPersist(persistRequest, actionVerb, dispatch);
  };

export const deleteRepair = (repairId: string) => () => async (dispatch: AppDispatch) => {
  const persistRequest = apiHelper.delete(`/api/repairs/${repairId}`);
  const actionVerb = 'supprimée';
  return doPersist(persistRequest, actionVerb, dispatch);
};
