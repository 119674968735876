import moment from 'moment';
import { Sort, SortDirection } from '../commons/model/sort';
import { RepairListView } from './repairTypes';
import { DateString } from '../commons/model/common';

const sortedStatuses = ['IN_PROGRESS', 'DONE'];

const sortByUpdatedDateTime = (direction: SortDirection) => (repair1: RepairListView, repair2: RepairListView) =>
  direction * moment(repair1.updatedDateTime).diff(moment(repair2.updatedDateTime));

const sortByStatus = (direction: SortDirection) => (repair1: RepairListView, repair2: RepairListView) => {
  if (repair1.status === repair2.status) {
    return sortByUpdatedDateTime(direction)(repair1, repair2);
  }
  const index1 = sortedStatuses.indexOf(repair1.status);
  const index2 = sortedStatuses.indexOf(repair2.status);
  return index1 < index2 ? -direction : direction;
};

const sortByRepairNumber = (direction: SortDirection) => (repair1: RepairListView, repair2: RepairListView) => {
  if (repair1.repairNumber > repair2.repairNumber) {
    return direction;
  }
  if (repair1.repairNumber < repair2.repairNumber) {
    return -direction;
  }
  return sortByUpdatedDateTime(direction)(repair1, repair2);
};

const sortByWagonRegistration = (direction: SortDirection) => (repair1: RepairListView, repair2: RepairListView) => {
  if (repair1.wagonRegistrationNumber > repair2.wagonRegistrationNumber) {
    return direction;
  }
  if (repair1.wagonRegistrationNumber < repair2.wagonRegistrationNumber) {
    return -direction;
  }
  return sortByUpdatedDateTime(direction)(repair1, repair2);
};

const sortByDate =
  (direction: SortDirection, getDate: (r: RepairListView) => DateString) =>
  (repair1: RepairListView, repair2: RepairListView) => {
    if (!getDate(repair1) && !getDate(repair2)) {
      return sortByUpdatedDateTime(direction)(repair1, repair2);
    }
    if (!getDate(repair1)) {
      return direction;
    }
    if (!getDate(repair2)) {
      return -direction;
    }
    return direction * moment(getDate(repair1)).diff(moment(getDate(repair2)));
  };

const sortByDuration = (direction: SortDirection) => (repair1: RepairListView, repair2: RepairListView) => {
  if (repair1.duration > repair2.duration) {
    return direction;
  }
  if (repair1.duration < repair2.duration) {
    return -direction;
  }
  return sortByUpdatedDateTime(direction)(repair1, repair2);
};

const getRepairSortFunction = (sortDefinition: Sort<RepairListView>) => {
  switch (sortDefinition.field) {
    case 'repairNumber':
      return sortByRepairNumber(sortDefinition.direction);
    case 'interventionRequestDate':
      return sortByDate(sortDefinition.direction, (r) => r.interventionRequestDate);
    case 'repairStartDate':
      return sortByDate(sortDefinition.direction, (r) => r.repairStartDate);
    case 'repairEndDate':
      return sortByDate(sortDefinition.direction, (r) => r.repairEndDate);
    case 'status':
      return sortByStatus(sortDefinition.direction);
    case 'duration':
      return sortByDuration(sortDefinition.direction);
    case 'wagonRegistrationNumber':
      return sortByWagonRegistration(sortDefinition.direction);
    default:
      return sortByUpdatedDateTime(sortDefinition.direction);
  }
};

export default getRepairSortFunction;
