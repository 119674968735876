import moment from 'moment';
import { DateString, IdName } from '../commons/model/common';
import { Market } from '../commons/model/templates';

export type RepairStatus = 'IN_PROGRESS' | 'DONE';

export const RepairStatusLabels: Record<RepairStatus, string> = {
  IN_PROGRESS: 'En cours',
  DONE: 'Terminé',
};

export type RepairListView = Readonly<{
  id: string;
  repairNumber: number;
  status: RepairStatus;
  wagonRegistrationNumber: string;
  damageReportNumber: string;
  interventionRequestDate: DateString;
  repairStartDate: DateString;
  repairEndDate: DateString;
  duration: number;
  marketName: string;
  marketAxis: string;
  workshopName: string;
  accidentNumber: string;
  updatedDateTime: DateString;
}>;
export type RepairsFiltersFormValues = Readonly<{
  fromRepairStartDate: moment.Moment | null;
  toRepairStartDate: moment.Moment | null;
  fromRepairEndDate: moment.Moment | null;
  toRepairEndDate: moment.Moment | null;
  repairNumberSearch: number | null;
  damageReportNumberSearch: number | null;
  wagonRegistrationNumberSearch: string | null;
  markets: Market[];
  market: IdName<number> | null;
  workshopId: string | null;
  accidentNumberSearch: string | null;
  statusInProgress: boolean;
  statusDone: boolean;
}>;

export type RepairsFiltersQueryParams = Readonly<{
  fromRepairStartDate: DateString | null;
  toRepairStartDate: DateString | null;
  fromRepairEndDate: DateString | null;
  toRepairEndDate: DateString | null;
  repairNumberSearch: number | null;
  damageReportNumberSearch: number | null;
  wagonRegistrationNumberSearch: string | null;
  marketIds: string | null;
  workshopId: string | null;
  accidentNumberSearch: string | null;
  statuses: string;
}>;

export const enum RepairSortDefinition {
  WAGON_REGISTRATION_NUMBER = 'wagonRegistrationNumber',
  INTERVENTION_REQUEST_DATE = 'interventionRequestDate',
  REPAIR_START_DATE = 'repairStartDate',
  REPAIR_END_DATE = 'repairEndDate',
  REPAIR_NUMBER = 'repairNumber',
  UPDATED_DATE_TIME = 'updatedDateTime',
  DURATION = 'duration',
}

export type RepairDetailsView = Readonly<{
  id: string;
  number: string;
  status: RepairStatus;
  wagonTemplate: WagonTemplateSimpleData;
  damageReport: DamageReportSimpleView | null;
  market: IdName<number>;
  workshop: IdName<string>;
  interventionRequestDate: DateString;
  repairStartDate: DateString | null;
  repairEndDate: DateString | null;
  accidentNumber: string | null;
  comment: string | null;
}>;

type WagonTemplateSimpleData = {
  id: number | null;
  registration: string | null;
};

export type InitRepairFormData = Readonly<{
  repairDetails: RepairDetailsView | null;
  initialWagonTemplate?: WagonTemplateSimpleData;
  initialDamageReport?: DamageReportSimpleView;
}>;

export type RepairFormValues = {
  id: string | null;
  status: RepairStatus;
  repairNumber: string | null;
  wagonTemplate: WagonTemplateSimpleData | null;
  damageReport: DamageReportSimpleView | null;
  interventionRequestDate: moment.Moment | null;
  repairStartDate: moment.Moment | null;
  repairEndDate: moment.Moment | null;
  market: IdName<number> | null;
  workshop: IdName<string> | null;
  accidentNumber: string | null;
  comment: string | null;
};

export type DamageReportSimpleView = Readonly<{
  id: string;
  damageReportNumber: number;
  wagonRegistration: string;
}>;
