/**
 * Vehicles library administration component
 */
import React from 'react';
import {
  selectExternalDamageReport,
  selectOverlay,
  selectSecurityContextRoles,
} from '../../commons/selectors/selectors';
import { LIBRARY_WRITE } from '../../commons/security/userRoles';
import VehiclesTopbar from './vehicles-tobpar/VehiclesTopbar';
import LibraryTable from './library-table/LibraryTable';
import WagonDetails from './wagon-details/WagonDetails';
import { useAppSelector } from '../../commons/store/hooks';
import { usePageTitle } from '../../commons/components/hooks';
import WagonExternalDamageReportFields from './wagon-details/form/WagonExternalDamageReportForm';

const VehiclesLibraryComponent = () => {
  usePageTitle('Véhicules');
  const overlay = useAppSelector(selectOverlay);
  const readOnly = !useAppSelector(selectSecurityContextRoles).includes(LIBRARY_WRITE);
  const { hidden } = useAppSelector(selectExternalDamageReport);
  return (
    <div className="content-parent table-page">
      <VehiclesTopbar readOnly={readOnly} />
      <LibraryTable readOnly={readOnly} />
      {overlay.active === 'vehicle-library' && <WagonDetails />}
      {!hidden && <WagonExternalDamageReportFields />}
    </div>
  );
};

export default VehiclesLibraryComponent;
