/**
 * Vehicles library table administration component
 */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import helpers from '../../../commons/helpers/helpers';
import './libraryTableStyles.scss';
import { getVehiclesLibrary } from '../../../commons/selectors/selectors';
import ScrollableTableBody from '../../../commons/components/table/ScrollableTableBody';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { loadLibrary, startEditWagon } from '../vehiclesLibraryDuck';
import { VehicleTemplate, WagonTemplate } from '../../../commons/model/templates';
import { CompanyOptions, TractionMode } from '../../../commons/model/common';
import { showOverlay } from '../../../commons/components/overlay/overlayDucks';
import { TRACTION_MODES } from '../../../commons/model/Vehicle';
import WagonTemplateLabels from '../../../commons/components/vehicles/vehicle/WagonTemplateLabels';
import WagonTemplateDamages from '../../../commons/components/vehicles/vehicle/WagonTemplateDamages';

type LibraryTableProps = {
  readOnly: boolean;
};

const formatTareKg = (tare: number | null) => (tare ? `${helpers.integerFormat(Math.ceil(tare))} kg` : '—');

const formatTareTons = (tare: number | null) =>
  typeof tare === 'number' ? `${helpers.integerFormat(tare / 1000)} t` : '—';

const formatAxles = (nbAxles: number | null) => (nbAxles ? `${helpers.integerFormat(nbAxles)} essieux` : '—');

const formatTractionMode = (tractionMode: TractionMode) => {
  if (!tractionMode) {
    return '';
  }
  const tm = TRACTION_MODES[tractionMode];
  return tm ? tm.label : '';
};

const shouldDisplayWagonStatus = (vehicle: VehicleTemplate) => vehicle.wagon && vehicle.status === 'TO_REVIEW';

const LibraryTable = ({ readOnly }: LibraryTableProps) => {
  const { libraryCollection, editedWagon } = useAppSelector(getVehiclesLibrary);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadLibrary());
    if (editedWagon != null) {
      dispatch(startEditWagon(editedWagon));
    }
  }, [dispatch, editedWagon]);

  const onWagonClick = (item: WagonTemplate) => {
    dispatch(startEditWagon(item));
    dispatch(showOverlay('vehicle-library'));
  };

  return (
    <div className="table table-vehicles-library">
      <div className="table-header">
        <div className="registration-cell">Immatriculation</div>
        <div className="cell-owner">Détenteur</div>
        <div className="cell-params">Paramètres</div>
        <div className="cell-company">Gestionnaire</div>
        <div className="cell-wagon-labels">Étiquettes</div>
        <div className="cell-wagon-damages">Avaries</div>
      </div>
      <ScrollableTableBody>
        {libraryCollection.map((item: VehicleTemplate, i: number) => () => (
          <div key={`row-${i}`} onClick={item.wagon && !readOnly ? () => onWagonClick(item) : () => {}}>
            <div
              className={classNames('table-body-row', {
                'row-wagon': item.wagon,
                'row-engine': !item.wagon,
              })}
            >
              <div className="registration-cell">
                <span>
                  {item.registration ? helpers.registrationFormat(item.registration) || item.registration : ''}
                </span>
              </div>
              <div className="cell-owner">{item.wagon && item.ownerName ? item.ownerName : '-'}</div>

              <div className="cell-params">
                <div className="value value-length">{item.length ? helpers.decimalFormat(item.length) : ''} m</div>
                {item.wagon ? (
                  <div className="value value-tare-wagon">{formatTareKg(item.tare)}</div>
                ) : (
                  <div className="value value-tare-engine">{formatTareTons(item.tare)}</div>
                )}
                <div className="value value-axles">{formatAxles(item.nbAxles)}</div>
                {!item.wagon && <div className="value value-mode">{formatTractionMode(item.tractionMode)}</div>}
              </div>
              <div className="cell-company">{(item.wagon && item.manager && CompanyOptions[item.manager]) ?? ''}</div>

              <div className="cell-wagon-labels">{item.wagon && <WagonTemplateLabels wagonTemplate={item} />}</div>
              <div className="cell-wagon-damages">{item.wagon && <WagonTemplateDamages wagonTemplate={item} />}</div>

              {!item.wagon && (
                <div className="sub-cell-number-serie">
                  {item.number} - {item.seriesNumber}
                </div>
              )}
              {shouldDisplayWagonStatus(item) && <div className="cell-wagon-template-status">À vérifier</div>}
            </div>
          </div>
        ))}
      </ScrollableTableBody>
    </div>
  );
};

export default LibraryTable;
