import PropTypes from 'prop-types';

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, Field, FieldArray, isDirty, reduxForm, touch } from 'redux-form';
import { DAMAGE_REPORT_VALIDATE, DAMAGE_REPORT_WRITE } from '../../../commons/security/userRoles';
import { saveDamageReport } from '../damageReportDucks';
import DamageReportEntity from '../../../commons/entity/DamageReportEntity';
import Mask from '../../../commons/mask/Mask';
import { normalizeRegistration } from '../../../commons/redux-form/valueNormalizer';
import DamageReportField from '../../../commons/components/input/DamageReportField';
import OwnerField from '../../../commons/components/input/OwnerField';
import DateTimeField from '../../../commons/components/input/DateTimeField';
import CheckboxFormComponent from '../../../commons/components/checkbox/CheckboxFormComponent';
import DamageReportDropdown from '../../../commons/components/dropdown/Dropdown';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import MultiCheckboxFormComponent from '../../../commons/components/checkbox/MultiCheckboxFormComponent';
import Damages from '../../../commons/components/damage/Damages';
import validateDamageReport, {
  asyncValidateDamageReport,
  validateWagonAvailabilityMode,
} from './damageReportValidation';
import Textarea from '../../../commons/components/input/Textarea';
import AnnexesSection from './annexes/AnnexesSection';
import Signature from './signature/Signature';
import DamageReportAudit from './audit/DamageReportAudit';
import FormSection from '../../../commons/components/form/FormSection';
import Switch3 from '../../../commons/components/switch3/Switch3';
import MarketField from '../../../commons/components/input/MarketField';
import wagonCache from '../../../commons/templates/wagonCache';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import { CompanyOptions } from '../../../commons/model/common';
import moment from 'moment';
import { startEditWagon } from '../../../admin/vehicles-library/vehiclesLibraryDuck';
import { showOverlay } from '../../../commons/components/overlay/overlayDucks';
import { browserHistory } from '../../../commons/routes/routerHistoryConfig';
import { wagonHasRedLabel } from '../../../admin/vehicles-library/vehiclesLibraryUtils';

const DamageReportFields = ({
  damageReport,
  hideIsInLabelsFromDate,
  readOnly,
  readOnlyForGeneralSection,
  changeField,
  currentUser,
  isFormDirty,
}) => {
  const dispatch = useDispatch();

  return (
    <form className="damage-report-form" autoComplete="off">
      <FormSection title="Général">
        <div className="fields">
          <DamageReportField
            name="wagon.registration"
            labelText="Immatriculation du wagon *"
            placeholder="Ex: 12 34 567 8901-2"
            format={(value) => value && Mask.REGISTRATION.format(value)}
            disabled={readOnly || readOnlyForGeneralSection}
            numeric={true}
            normalize={normalizeRegistration}
            autocompleter={wagonCache}
            // if the wagon is during the repair or wagon has RED label, we should not show it in the preAdvice form
            autocompleteFilter={(template) => !template.inProgressRepairId && !wagonHasRedLabel(template)}
            saveAction={(data, propertyToUpdate) => (dispatch) => {
              const updateCommand = { ...propertyToUpdate };
              const { wagon = {} } = updateCommand;
              if (wagon.ownerId) {
                updateCommand.wagon.owner = {
                  id: wagon.ownerId,
                  name: wagon.ownerName,
                };
                changeField('wagon.owner.name', wagon.ownerName);
              } else {
                updateCommand.wagon.owner = null;
                changeField('wagon.owner.name', '');
              }
              updateCommand.wagon.availabilityMode = data?.wagon?.availabilityMode;
              dispatch(saveDamageReport(data, updateCommand));
            }}
            data={damageReport}
          />
          <OwnerField
            name="wagon.owner.name"
            labelText="Détenteur *"
            className="icon star"
            placeholder="Détenteur"
            wagonRegistration={damageReport.wagon.registration}
            disabled={readOnly || readOnlyForGeneralSection}
            saveAction={saveDamageReport}
            data={damageReport}
          />
          <MarketField
            name="market.name"
            className="icon star"
            labelText="Marché *"
            placeholder="Choisir le marché"
            saveAction={saveDamageReport}
            disabled={readOnly || readOnlyForGeneralSection}
            data={damageReport}
          />
          <DateTimeField
            name="creationDate"
            labelText="Date d'établissement"
            disabled={true}
            data={damageReport}
            timeFormat="à HH:mm"
          />
          <DateTimeField
            name="validatedDate"
            labelText="Date de validation et d'envoi"
            disabled={true}
            placeholder="Ce champ sera rempli automatiquement"
            data={damageReport}
            timeFormat="à HH:mm"
          />
          <div className="input loaded">
            <Field
              component={CheckboxFormComponent}
              name="wagon.loaded"
              disabled={readOnly || readOnlyForGeneralSection}
              data={damageReport}
              saveAction={saveDamageReport}
              label="Chargé"
            />
          </div>
          <Field
            component={Dropdown}
            name="company"
            disabled={true}
            data={damageReport}
            options={CompanyOptions}
            labelText="Entreprise ferroviaire"
          />
        </div>
      </FormSection>
      <FormSection title="Train">
        <div className="fields two-columns">
          <DamageReportField
            name="train.trainNumber"
            labelText="N° du train *"
            placeholder=""
            disabled={readOnly}
            numeric={true}
            saveAction={saveDamageReport}
            data={damageReport}
          />
          <DateTimeField
            name="train.startDateTime"
            labelText="Date d'expedition *"
            saveAction={saveDamageReport}
            disabled={readOnly}
            inputReadOnly={true}
            data={damageReport}
          />
          <DamageReportField
            name="train.startStation"
            labelText="Gare de départ *"
            placeholder=""
            disabled={readOnly}
            saveAction={saveDamageReport}
            data={damageReport}
          />
          <DamageReportField
            name="train.endStation"
            labelText="Gare d'arrivée *"
            placeholder=""
            disabled={readOnly}
            saveAction={saveDamageReport}
            data={damageReport}
          />
        </div>
      </FormSection>
      <FormSection title="Dommages">
        <div className="fields">
          <div className="full-width">
            <FieldArray
              name="wagonDamages.damages"
              canLabelBeChanged={true}
              component={Damages}
              disabled={readOnly}
              allowIntermediary={true}
              minDamages={1}
              maxDamages={3}
              saveAction={saveDamageReport}
              data={damageReport}
            />
          </div>
          <div className="fields two-columns">
            <DamageReportField
              name="wagonDamages.observation.location"
              labelText="Lieu de constatation du dommage *"
              placeholder=""
              disabled={readOnly}
              saveAction={saveDamageReport}
              data={damageReport}
            />
            <DateTimeField
              name="wagonDamages.observation.dateTime"
              labelText="Date de constatation du dommage *"
              saveAction={saveDamageReport}
              disabled={readOnly}
              inputReadOnly={true}
              data={damageReport}
            />
          </div>

          <div className="full-width-input">
            <DamageReportField
              name="wagonDamages.comment"
              component={Textarea}
              labelText="Observations supplémentaires"
              placeholder=""
              disabled={readOnly}
              saveAction={saveDamageReport}
              data={damageReport}
            />
          </div>
        </div>
      </FormSection>

      <FormSection title="Étiquetage">
        <div className="subtitle">
          Étiquetage existant
          {damageReport.wagon.registration && (
            <button
              className="subtitle-link"
              type="button"
              onClick={() => {
                wagonCache.findItemById(damageReport.wagon.registration).then((wagon) => {
                  dispatch(startEditWagon(wagon));
                  dispatch(showOverlay('vehicle-library'));
                  browserHistory.push('/admin/vehicles-library');
                });
              }}
            >
              Voir les détails du wagon
            </button>
          )}
        </div>
        <div className="fields">
          <div className="full-width">
            <MultiCheckboxFormComponent
              name="labelling.labels"
              disabled={true}
              labelText="Étiquettes"
              data={damageReport}
              saveAction={(data, propertyToUpdate) => (dispatch) => {
                const updateCommand = { ...propertyToUpdate };
                if (updateCommand['labelling.labels'].length === 0) {
                  updateCommand['labelling.railCompany'] = null;
                  changeField('labelling.railCompany', null);
                  updateCommand['labelling.dateTime'] = null;
                  changeField('labelling.dateTime', null);
                }
                dispatch(saveDamageReport(data, updateCommand));
              }}
              options={DamageReportEntity.LABELS
                // if the damageReport has been created from 2025/02/01, then hide IS & IN
                .filter((l) =>
                  moment(damageReport.creationDate).isAfter(moment(hideIsInLabelsFromDate))
                    ? !['IS', 'IN'].includes(l.value)
                    : true,
                )
                .filter((l) => l.value !== 'ONE')}
            />
          </div>
        </div>
        <div className="subtitle">Étiquetage effectué</div>
        <div className="fields">
          <div className="full-width">
            <MultiCheckboxFormComponent
              name="newLabels"
              disabled={readOnly}
              labelText="Étiquettes *"
              data={damageReport}
              saveAction={saveDamageReport}
              options={DamageReportEntity.LABELS
                // if the damageReport has been created from 2025/02/01, then hide IS & IN
                .filter((l) =>
                  moment(damageReport.creationDate).isAfter(moment(hideIsInLabelsFromDate))
                    ? !['IS', 'IN'].includes(l.value)
                    : true,
                )}
            />
            <DamageReportField
              component={Switch3}
              data={damageReport}
              disabled={readOnly}
              getError={validateWagonAvailabilityMode}
              labelText="Mise à disposition à l'atelier"
              labelLeft="Avant déchargement"
              labelRight="Après déchargement"
              name="wagon.availabilityMode"
              saveAction={saveDamageReport}
              valueLeft={DamageReportEntity.WAGON_AVAILABILITY_MODE.BEFORE_UNLOADING}
              valueRight={DamageReportEntity.WAGON_AVAILABILITY_MODE.AFTER_UNLOADING}
            />
          </div>
        </div>
        <div className="subtitle">Prise en charge en provenance de</div>
        <div className="fields two-columns">
          <Field
            component={DamageReportDropdown}
            disabled={readOnly}
            labelText="Origine *"
            name="wagon.railCompany.type"
            options={{
              none: 'Aucun',
              ...DamageReportEntity.RAIL_COMPANY_TYPES,
            }}
            format={(value) => value || 'none'}
            saveAction={(data, propertyToUpdate) => (dispatch) => {
              const updateCommand = { ...propertyToUpdate };
              if (updateCommand['wagon.railCompany.type'] === 'none') {
                updateCommand['wagon.railCompany.type'] = null;
                changeField('wagon.railCompany.type', 'none');
                updateCommand['wagon.railCompany.name'] = null;
                changeField('wagon.railCompany.name', null);
              }
              dispatch(saveDamageReport(data, updateCommand));
            }}
            data={damageReport}
          />
          {damageReport.wagon?.railCompany?.type && damageReport.wagon.railCompany.type !== 'none' && (
            <DamageReportField
              name="wagon.railCompany.name"
              labelText="Nom *"
              placeholder=""
              disabled={readOnly}
              saveAction={saveDamageReport}
              data={damageReport}
            />
          )}
        </div>
      </FormSection>

      <FormSection title="Responsable">
        <div className="fields two-columns">
          <Field
            component={DamageReportDropdown}
            disabled={readOnly}
            labelText="Responsable *"
            name="cause.type"
            options={DamageReportEntity.CAUSE_TYPES}
            saveAction={(data, propertyToUpdate) => (dispatch) => {
              const updateCommand = { ...propertyToUpdate };
              if (updateCommand['cause.type'] !== 'THIRD_PARTY') {
                [
                  'cause.responsibleThirdParty.name',
                  'cause.responsibleThirdParty.address',
                  'cause.responsibleThirdParty.signature',
                ].forEach((field) => {
                  updateCommand[field] = null;
                  changeField(field, null);
                });
              }
              dispatch(saveDamageReport(data, updateCommand));
            }}
            data={damageReport}
          />
        </div>
        {damageReport.cause && damageReport.cause.type === 'THIRD_PARTY' && (
          <div className="fields two-columns">
            <div className="column full-width-input">
              <DamageReportField
                name="cause.responsibleThirdParty.name"
                labelText="Nom du tiers"
                placeholder=""
                data={damageReport}
                disabled={damageReport.isStatusDisabled()}
                saveAction={saveDamageReport}
              />
              <DamageReportField
                name="cause.responsibleThirdParty.address"
                component={Textarea}
                labelText="Adresse du tiers"
                placeholder=""
                data={damageReport}
                disabled={damageReport.isStatusDisabled()}
                saveAction={saveDamageReport}
              />
            </div>
            <Signature />
          </div>
        )}
      </FormSection>

      <FormSection title="Lieu et date">
        <div className="fields flex">
          <DamageReportField
            name="reportLocation"
            labelText="Lieu *"
            placeholder=""
            disabled={readOnly}
            saveAction={saveDamageReport}
            data={damageReport}
          />
          <DateTimeField
            name="reportDateTime"
            labelText="Date *"
            saveAction={saveDamageReport}
            disabled={readOnly}
            inputReadOnly={true}
            data={damageReport}
          />
          {damageReport.submittedBy && (
            <div className="input author">
              <div>
                <label htmlFor={damageReport.submittedBy.firstName}>Rédacteur</label>
                {`${damageReport.submittedBy.firstName} ${damageReport.submittedBy.lastName}`}
              </div>
            </div>
          )}
        </div>
      </FormSection>
      <AnnexesSection damageReport={damageReport} readOnly={readOnly} />
      <DamageReportAudit currentUser={currentUser} damageReport={damageReport} isFormDirty={isFormDirty} />
    </form>
  );
};
DamageReportFields.propTypes = {
  damageReport: PropTypes.shape(DamageReportEntity.reactShape()).isRequired,
  hideIsInLabelsFromDate: PropTypes.string.isRequired,
};

const isFormReadOnly = (damageReport, userInfo) => {
  if (damageReport.company !== userInfo.company) {
    return true;
  }
  if (damageReport.isStatusNewOrInProgress()) {
    return !userInfo.roles.includes(DAMAGE_REPORT_WRITE);
  }
  if (damageReport.isStatusSubmitted()) {
    return !userInfo.roles.includes(DAMAGE_REPORT_VALIDATE);
  }

  //ICPO-1407: PVCA validator can edit a validated PVCA, except the general section
  if (damageReport.isStatusValidated()) {
    return !userInfo.roles.includes(DAMAGE_REPORT_VALIDATE);
  }
  return true;
};

const isGeneralSectionReadOnly = (damageReport, userInfo) => {
  if (damageReport.company !== userInfo.company) {
    return true;
  }
  if (damageReport.isStatusNewOrInProgress()) {
    return !userInfo.roles.includes(DAMAGE_REPORT_WRITE);
  }
  if (damageReport.isStatusSubmitted()) {
    return !userInfo.roles.includes(DAMAGE_REPORT_VALIDATE);
  }
  return true;
};

const selector = (state, ownProps) => ({
  readOnly: isFormReadOnly(ownProps.damageReport, selectSecurityContext(state)),
  readOnlyForGeneralSection: isGeneralSectionReadOnly(ownProps.damageReport, selectSecurityContext(state)),
  initialValues: ownProps.damageReport,
  currentUser: selectSecurityContext(state),
  isFormDirty: isDirty('damageReportForm')(state),
});

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = (dispatch) => ({
  touchField: (fieldName) => dispatch(touch('damageReportForm', fieldName)),
  changeField: (fieldName, value) => dispatch(change('damageReportForm', fieldName, value)),
});

export default connect(
  selector,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'damageReportForm',
    asyncChangeFields: [],
    asyncBlurFields: ['wagon.owner.name', 'market.name', 'wagon.registration', 'wagonDamages.damages'],
    asyncValidate: asyncValidateDamageReport,
    validate: validateDamageReport,
  })(DamageReportFields),
);
