import React, { useEffect } from 'react';
import RepairsFilters from './RepairsFilters';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { repairsFiltersToQueryParams, requestGetRepairs } from '../RepairsDucks';
import classNames from 'classnames';
import { useViewportWidth } from '../../commons/responsive/hooks';
import apiHelper from '../../api/apiHelper';
import { useSelector } from 'react-redux';
import { getRepairsState, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { createRepair } from '../RepairDucks';

const RepairsTopBar = ({ loading }: { loading: boolean }) => {
  const { isMobile } = useViewportWidth();
  const roles = useAppSelector(selectSecurityContextRoles);
  const dispatch = useAppDispatch();
  const { filters } = useSelector(getRepairsState);

  const exportParams = apiHelper.toQueryString(repairsFiltersToQueryParams(filters));
  const exportHref = `/api/repairs/export?${exportParams}`;

  const loadRepairs = () => dispatch(requestGetRepairs());
  const handleCreateRepair = () => dispatch(createRepair({ repairDetails: null }));

  useEffect(() => {
    dispatch(requestGetRepairs());
  }, [dispatch]);

  return (
    <div className="topbar">
      <h1 className="title">Réparations</h1>
      {loading ? (
        <div className="loader" />
      ) : (
        <button className="btn btn-round refresh" onClick={loadRepairs} type="button">
          Rafraîchir
        </button>
      )}
      <RepairsFilters />
      <a
        className={classNames('btn xls', isMobile ? 'btn-round' : 'btn-link btn-icon')}
        href={exportHref}
        target="_blank"
        rel="noreferrer"
      >
        Exporter
      </a>
      {roles.includes('REPAIR_WRITE') && (
        <button
          className={classNames('btn btn-success', isMobile && 'btn-round plus')}
          onClick={handleCreateRepair}
          type="button"
        >
          Ajouter
        </button>
      )}
    </div>
  );
};

export default RepairsTopBar;
