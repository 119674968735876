import helpers from '../../commons/helpers/helpers';
import { startToast } from '../../commons/components/toast/toastDucks';
import PreAdviceEntity from '../../commons/entity/PreAdviceEntity';
import containsErrors, { getValidationMessage } from '../../commons/validation/validationUtils';
import { getPreAdviceState } from '../../commons/selectors/selectors';
import { hideOverlay } from '../../commons/components/overlay/overlayDucks';
import { formName } from './preAdviceFormUtils';
import { touch } from 'redux-form';
import { openConfirmDialog } from '../../commons/components/modal/confirmModalDucks';
import PreAdviceWagonEntity from '../../commons/entity/PreAdviceWagonEntity';
import { browserHistory } from '../../commons/routes/routerHistoryConfig';
import apiHelper from '../../api/apiHelper';

/*
 * ACTIONS
 */
const SUCCESS_GET_PRE_ADVICE = 'SUCCESS_GET_PRE_ADVICE';
const START_GET_PRE_ADVICE = 'START_GET_PRE_ADVICE';
const ERROR_GET_PRE_ADVICE = 'ERROR_GET_PRE_ADVICE';
const UNLOAD_PRE_ADVICE = 'UNLOAD_PRE_ADVICE';
const SUCCESS_DELETE_PRE_ADVICE = 'SUCCESS_DELETE_PRE_ADVICE';
const RESET_PRE_ADVICE = 'RESET_PRE_ADVICE';
const INVERSE_PRE_ADVICE = 'INVERSE_PRE_ADVICE';
const IMPORT_COMPOSITION_INTO_PRE_ADVICE = 'IMPORT_COMPOSITION_INTO_PRE_ADVICE';
const SUCCESS_ADD_WAGONS_TO_PRE_ADVICE = 'SUCCESS_ADD_WAGONS_TO_PRE_ADVICE';
const SUCCESS_UPDATE_PRE_ADVICE_WAGONS = 'SUCCESS_UPDATE_PRE_ADVICE_WAGONS';
const SUCCESS_DELETE_PRE_ADVICE_WAGON = 'SUCCESS_DELETE_PRE_ADVICE_WAGON';

/*
 * ACTION CREATORS
 */
const startGetPreAdvice = () => ({
  type: START_GET_PRE_ADVICE,
});

/**
 * @param preAdvice {PreAdviceEntity}
 */
export const successGetPreAdvice = (preAdvice) => ({
  type: SUCCESS_GET_PRE_ADVICE,
  payload: {
    preAdvice,
  },
});

const errorGetPreAdvice = () => ({
  type: ERROR_GET_PRE_ADVICE,
});

export const requestLoadNewPreAdvice = () => ({
  type: SUCCESS_GET_PRE_ADVICE,
  payload: {
    preAdvice: PreAdviceEntity.newPreAdvice(),
  },
});

export const successAddWagons = (wagons) => ({
  type: SUCCESS_ADD_WAGONS_TO_PRE_ADVICE,
  payload: { wagons },
});

/**
 * @param wagons The wagons that have been updated (subset of pre-advice wagons).
 * @returns {{payload: {wagons: *}, type: string}}
 */
export const successUpdateWagons = (wagons) =>
  successUpdateWagonEntities(wagons.map((w) => new PreAdviceWagonEntity(w)));

/**
 * @param wagons The wagons that have been updated (subset of pre-advice wagons).
 * @returns {{payload: {wagons: *}, type: string}}
 */
export const successUpdateWagonEntities = (wagons) => ({
  type: SUCCESS_UPDATE_PRE_ADVICE_WAGONS,
  payload: { wagons },
});

export const successDeleteWagon = (wagonId) => ({
  type: SUCCESS_DELETE_PRE_ADVICE_WAGON,
  payload: { wagonId },
});

export const successDeletePreAdvice = (preAdviceId) => ({
  type: SUCCESS_DELETE_PRE_ADVICE,
  payload: { preAdviceId },
});

export const requestGetPreAdvice = (preAdviceId) => async (dispatch) => {
  dispatch(startGetPreAdvice());
  try {
    const preAdvice = await apiHelper.get(`/api/pre-advices/${preAdviceId}`);
    dispatch(successGetPreAdvice(new PreAdviceEntity(preAdvice)));
  } catch {
    dispatch(errorGetPreAdvice());
  }
};

/**
 * Function that add a valid value for a property to the currently loaded pre-advice.
 * If all the required fields to create a pre-advice are valid, then the pre-advice
 * is persisted server-side.
 *
 * @param preAdvice
 * @param propertyToUpdate
 * @returns {function(...[*]=)}
 */
export const requestCreatePreAdvice = (preAdvice, propertyToUpdate) => async (dispatch) => {
  helpers.setIn(preAdvice, propertyToUpdate);
  if (containsErrors(PreAdviceEntity.validate(preAdvice))) {
    return;
  }

  const requestBody = preAdvice.toSaveOrUpdateCommand();
  try {
    const persistedPreAdvice = await apiHelper.post('/api/pre-advices', requestBody);
    browserHistory.push(`/pre-advices/${persistedPreAdvice.id}`);
    dispatch(startToast({ className: 'success', text: 'La pré-annonce a bien été créée.' }));
  } catch {
    dispatch(startToast({ className: 'error', text: "Erreur : la pré-annonce n'a pas été créée." }));
  }
};

/**
 * Function that updates front-side and server-side a property to the given pre-advice.
 * The given pre-advice contains only valid values: invalid values in the form are not
 * propagated to the given pre-advice.
 *
 * This means that a property can be updated even if there is an invalid field in the form.
 *
 * @param preAdvice
 * @param propertyToUpdate
 * @returns {function(...[*]=)}
 */
export const requestUpdatePreAdvice = (preAdvice, propertyToUpdate) => async (dispatch) => {
  helpers.setIn(preAdvice, propertyToUpdate);
  const requestBody = preAdvice.toSaveOrUpdateCommand();
  try {
    const persistedPreAdvice = await apiHelper.put(`/api/pre-advices/${preAdvice.id}`, requestBody);
    dispatch(successGetPreAdvice(new PreAdviceEntity(persistedPreAdvice)));
  } catch {
    dispatch(startToast({ className: 'error', text: "Erreur : la pré-annonce n'a pas été enregistrée." }));
  }
};

export const requestUnloadPreAdvice = () => ({
  type: UNLOAD_PRE_ADVICE,
});

export const requestAddWagons = (numberOfWagons) => async (dispatch, getState) => {
  const preAdvice = getPreAdviceState(getState()).preAdvice;
  try {
    const persistedWagons = await apiHelper.post(`/api/pre-advices/${preAdvice.id}/wagons`, { numberOfWagons });
    dispatch(successAddWagons(persistedWagons.map((w) => new PreAdviceWagonEntity(w))));
  } catch {
    dispatch(startToast({ className: 'error', text: "Erreur : les wagons n'ont pas été ajoutés." }));
  }
};

export const requestDeleteWagon = (wagonId, navigateAfterDeletion) => () => async (dispatch, getState) => {
  const preAdvice = getPreAdviceState(getState()).preAdvice;
  try {
    const persistedWagons = await apiHelper.delete(`/api/pre-advices/${preAdvice.id}/wagons/${wagonId}`);
    dispatch(successDeleteWagon(wagonId));
    dispatch(successUpdateWagons(persistedWagons));
    if (navigateAfterDeletion) {
      browserHistory.push(`/pre-advices/${preAdvice.id}`);
    }
  } catch {
    dispatch(startToast({ className: 'error', text: "Erreur : le wagon n'a pas été supprimé." }));
  }
};

export const requestDeletePreAdvice = () => () => async (dispatch, getState) => {
  const preAdvice = getPreAdviceState(getState()).preAdvice;
  try {
    await apiHelper.delete(`/api/pre-advices/${preAdvice.id}`);
    dispatch(successDeletePreAdvice(preAdvice.id));
    browserHistory.push('/pre-advices');
  } catch {
    dispatch(startToast({ className: 'error', text: "Erreur : la pré-annonce n'a pas été supprimée." }));
  }
};

export const requestResetPreAdvice = (preAdvice) => async (dispatch) => {
  dispatch({ type: RESET_PRE_ADVICE, payload: { id: preAdvice.id } });

  try {
    const persistedPreAdvice = await apiHelper.post('/api/pre-advices/reset', { id: preAdvice.id });
    dispatch(successGetPreAdvice(new PreAdviceEntity(persistedPreAdvice)));
    dispatch(startToast({ text: 'La pré-annonce a été réinitialisée.', className: 'success' }));
  } catch {
    dispatch(startToast({ text: "Erreur : la pré-annonce n'a pas été réinitialisée.", className: 'error' }));
  }
};

export const requestInversePreAdvice = (preAdvice) => async (dispatch) => {
  dispatch({ type: INVERSE_PRE_ADVICE });

  try {
    const persistedPreAdvice = await apiHelper.post('/api/pre-advices/inverse', { id: preAdvice.id });
    dispatch(successGetPreAdvice(new PreAdviceEntity(persistedPreAdvice)));
    dispatch(startToast({ text: 'La pré-annonce a été inversée', className: 'success' }));
  } catch {
    dispatch(startToast({ text: "Erreur : la pré-annonce n'a pas été inversée", className: 'error' }));
  }
};

export const requestImportCompositionIntoPreAdvice =
  ({ id: sourceId, type: sourceType }) =>
  (values) =>
  async (dispatch, getState) => {
    const preAdviceId = getState().preAdvice.preAdvice.id;
    const isWagonToBeLoaded = values.copyWagonsLoad;
    dispatch({
      type: IMPORT_COMPOSITION_INTO_PRE_ADVICE,
      payload: {
        preAdviceId,
        sourceId,
        sourceType,
        isWagonToBeLoaded,
      },
    });

    try {
      const persistedPreAdvice = await apiHelper.post('/api/pre-advices/import', {
        preAdviceId,
        sourceId,
        sourceType,
        wagonToBeLoaded: isWagonToBeLoaded,
      });
      dispatch(successGetPreAdvice(new PreAdviceEntity(persistedPreAdvice)));
      dispatch(startToast({ text: 'La composition a été importée.', className: 'success' }));
      dispatch(hideOverlay());
    } catch {
      dispatch(startToast({ text: "Erreur : la composition n'a pas été importée.", className: 'error' }));
    }
  };

const doRequestUpdatePreAdviceStatus = (preAdvice, status) => async (dispatch) => {
  try {
    const persistedPreAdvice = await apiHelper.put(`/api/pre-advices/${preAdvice.id}/status`, { status });
    dispatch(successGetPreAdvice(new PreAdviceEntity(persistedPreAdvice)));
    dispatch(
      startToast({
        text: status === 'VALIDATED' ? 'Validation réussie.' : "La pré-annonce n'est plus validée.",
        className: 'success',
      }),
    );
  } catch {
    dispatch(
      startToast({
        text: `Erreur : ${status === 'VALIDATED' ? 'la validation ' : "l'invalidation"} a échoué.`,
        className: 'error',
      }),
    );
  }
};

const requestUpdatePreAdviceStatus = (preAdvice, status) => {
  const verb = status === 'VALIDATED' ? 'valider' : 'invalider';
  const actionClass = status === 'VALIDATED' ? 'success' : 'danger';
  return openConfirmDialog({
    title: `Souhaitez-vous ${verb} 
 la pré-annonce ?`,
    actionText: verb,
    action: () => doRequestUpdatePreAdviceStatus(preAdvice, status),
    actionClass,
  });
};

const processValidation = (preAdvice, validationErrors, dispatch) => {
  if (containsErrors(validationErrors)) {
    dispatch(
      startToast({
        text: 'La validation a échoué : ' + getValidationMessage(validationErrors),
        className: 'error',
      }),
    );
  } else {
    dispatch(requestUpdatePreAdviceStatus(preAdvice, 'VALIDATED'));
  }
};

export const requestValidatePreAdvice = (preAdvice, hasRedLabel) => (dispatch, getState) => {
  // Validate form AND underlying entity to be able to see last incorrect changes,
  // and catch cases where the entity was not correctly updated
  const form = getState().form[formName];
  const validationErrors = {
    ...PreAdviceEntity.validate(form.values),
    wagons: preAdvice.validateWagons(),
    damageReport: hasRedLabel ? 'La composition contient au moins un wagon avec une étiquette rouge' : null,
  };
  processValidation(preAdvice, validationErrors, dispatch);
  dispatch(touch(formName, ...Object.keys(form.registeredFields).map((key) => form.registeredFields[key].name)));
};

export const requestInvalidatePreAdvice = (preAdvice) => requestUpdatePreAdviceStatus(preAdvice, 'NOT_VALIDATED');

/*
 * REDUCER
 */

const initialState = {
  error: false,
  loading: false,
  preAdvice: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_GET_PRE_ADVICE:
      return {
        ...initialState,
        loading: true,
      };
    case SUCCESS_GET_PRE_ADVICE:
      return {
        error: false,
        loading: false,
        preAdvice: action.payload.preAdvice,
      };
    case ERROR_GET_PRE_ADVICE:
      return {
        ...initialState,
        error: true,
      };
    case UNLOAD_PRE_ADVICE:
      return initialState;
    case SUCCESS_ADD_WAGONS_TO_PRE_ADVICE:
    case SUCCESS_UPDATE_PRE_ADVICE_WAGONS:
      return {
        ...state,
        preAdvice: state.preAdvice.copyWithPersistedWagons(action.payload.wagons),
      };
    case SUCCESS_DELETE_PRE_ADVICE_WAGON:
      return {
        ...state,
        preAdvice: state.preAdvice.copyWithoutWagon(action.payload.wagonId),
      };
    default:
      return state;
  }
};
