import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';

import securityContext, { SecurityContextState } from '../security/securityContextDucks';
import appState, { AppState } from '../app-state/appStateReducer';
import sidebar, { SidebarState } from '../components/sidebar/sidebarDucks';
import confirmModal, { ConfirmModalState } from '../components/modal/confirmModalDucks';
import externalDamageReport from '../../admin/vehicles-library/wagon-details/form/wagonExternalDucks';
import toast, { ToastState } from '../components/toast/toastDucks';
import trainSummaries, { TrainSummariesState } from '../../trains/trains-table/trainSummariesDucks';
import trainStep, { TrainStepState } from '../../trains/step/trainStepDucks';
import train, { TrainState } from '../../trains/train/trainDucks';
import offlineTrains, { OfflineTrainsState } from '../../trains/offline/offlineTrainsDucks';
import templates, { TemplatesState } from '../templates/templatesDucks';
import dragAndDrop, { DragAndDropState } from '../components/vehicles/dragAndDropDucks';
import overlay, { OverlayState } from '../components/overlay/overlayDucks';
import sentMessages from '../../admin/sent-messages/sentMessagesDucks';
import users, { UsersState } from '../../admin/users/usersDucks';
import user, { UserState } from '../../admin/users/userDucks';
import profiles, { ProfilesState } from '../../admin/users-profiles/profilesDucks';
import profile, { ProfileState } from '../../admin/users-profiles/profileDucks';
import vehiclesLibrary from '../../admin/vehicles-library/vehiclesLibraryDuck';
import mailingLists from '../../admin/mailing-lists/mailingListsDucks';
import mailingList from '../../admin/mailing-lists/mailingListDucks';
import damageReports from '../../damage-reports/damage-reports-table/damageReportsDucks';
import damageReport from '../../damage-reports/damage-report/damageReportDucks';
import owners from '../../admin/owners/ownersDucks';
import owner from '../../admin/owners/owner-details/ownerDucks';
import preAdviceSummaries from '../../pre-advices/preAdvicesDucks';
import preAdvice from '../../pre-advices/pre-advice/preAdviceDucks';
import preAdviceWagon from '../../pre-advices/wagon/preAdviceWagonDucks';
import copyJourney from '../../pre-advices/wagon/journey/copyJourneyDucks';
import copyWagons from '../components/copy-wagons/copyWagonsDucks';
import repairs, { RepairsState } from '../../repairs/RepairsDucks';
import repair, { RepairState } from '../../repairs/RepairDucks';
import persist from '../../persist/persistReducer';
import {
  damageReportsPersistConfig,
  offlineTrainsPersistConfig,
  securityContextPersistConfig,
  trainSummariesPersistConfig,
} from '../offline/persistConfig';
import { FormStateMap } from 'redux-form/lib/reducer';
import { ExternalDamageReportModalState } from '../../admin/vehicles-library/wagon-details/form/wagonExternalDucks';

const rootReducer = combineReducers({
  appState,
  confirmModal,
  copyJourney,
  copyWagons,
  damageReports: persistReducer(damageReportsPersistConfig, damageReports),
  damageReport,
  dragAndDrop,
  form: formReducer,
  mailingLists,
  mailingList,
  offlineTrains: persistReducer(offlineTrainsPersistConfig, offlineTrains),
  overlay,
  owners,
  owner,
  persist,
  preAdvice,
  preAdviceSummaries,
  preAdviceWagon,
  profiles,
  profile,
  securityContext: persistReducer(securityContextPersistConfig, securityContext),
  sentMessages,
  sidebar,
  templates,
  toast,
  train,
  trainStep,
  trainSummaries: persistReducer(trainSummariesPersistConfig, trainSummaries),
  users,
  user,
  vehiclesLibrary,
  repairs,
  repair,
  externalDamageReport,
});

export type RootState = {
  appState: AppState;
  confirmModal: ConfirmModalState;
  copyJourney: any;
  copyWagons: any;
  damageReports: any;
  damageReport: any;
  dragAndDrop: DragAndDropState;
  form: FormStateMap;
  mailingLists: any;
  mailingList: any;
  offlineTrains: OfflineTrainsState;
  overlay: OverlayState;
  owners: any;
  owner: any;
  persist: any;
  preAdvice: any;
  preAdviceSummaries: any;
  preAdviceWagon: any;
  repairs: RepairsState;
  repair: RepairState;
  profiles: ProfilesState;
  profile: ProfileState;
  securityContext: SecurityContextState;
  sentMessages: any;
  sidebar: SidebarState;
  templates: TemplatesState;
  toast: ToastState;
  train: TrainState;
  trainStep: TrainStepState;
  trainSummaries: TrainSummariesState;
  users: UsersState;
  user: UserState;
  vehiclesLibrary: any;
  externalDamageReport: ExternalDamageReportModalState;
};

export default rootReducer;
