import { WagonTemplateDamageReport } from '../../../commons/model/templates';
import WagonDamageReportDetails from './WagonDamageReportDetails';
import React from 'react';
import { useAppDispatch } from '../../../commons/store/hooks';
import { openExternalDamageReportDialog } from './form/wagonExternalDucks';

type WagonDamageReportListProps = {
  registration: string;
  damageReports: WagonTemplateDamageReport[];
  canOpenDamageReport: boolean;
};

const WagonDamageReportList = ({ registration, damageReports, canOpenDamageReport }: WagonDamageReportListProps) => {
  const sortedDamageReports = [...damageReports].sort((a, b) => b.validatedDate.localeCompare(a.validatedDate));

  const dispatch = useAppDispatch();
  return (
    <span>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <label>Avaries/étiquettes de réforme</label>
        {canOpenDamageReport && (
          <button
            className="btn-icon add"
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(openExternalDamageReportDialog({ registration }))}
            type="button"
          />
        )}
      </div>
      {sortedDamageReports.length > 0 && (
        <table className="wagon-damage-report-details">
          <tbody>
            <tr>
              <th>N° PVCA</th>
              <th>Étiquettes</th>
              <th>Code d'avarie</th>
              <th>Validation</th>
            </tr>
            {sortedDamageReports.map((damageReport) => (
              <WagonDamageReportDetails
                key={`wagon_damage_report_${damageReport.number}_details`}
                damageReport={damageReport}
              />
            ))}
          </tbody>
        </table>
      )}
    </span>
  );
};

export default WagonDamageReportList;
