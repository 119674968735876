import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { selectRepair, selectSecurityContextRoles } from '../../commons/selectors/selectors';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { DamageReportSimpleView, RepairFormValues } from '../repairTypes';
import { deleteRepair, persistRepairStart } from '../RepairDucks';
import { RootState } from '../../commons/reducers/rootReducer';
import Overlay from '../../commons/components/overlay/Overlay';
import Input from '../../commons/components/input/Input';
import Mask from '../../commons/mask/Mask';
import { normalizeRegistration } from '../../commons/redux-form/valueNormalizer';
import wagonCache from '../../commons/templates/wagonCache';
import { WagonTemplate } from '../../commons/model/templates';
import MarketField from '../../commons/components/input/MarketField';
import WorkshopField from '../../commons/components/input/WorkshopField';
import { hideOverlay } from '../../commons/components/overlay/overlayDucks';
import Textarea from '../../commons/components/input/Textarea';
import DateTimeField from '../../commons/components/input/DateTimeField';
import { browserHistory } from '../../commons/routes/routerHistoryConfig';
import moment from 'moment/moment';
import './repairDetailsStyles.scss';
import { asyncValidateRepairDetailsForm, validateRepairDetailsForm } from '../repairUtils';
import DamageReportInRepairFormAutocompleter from '../../commons/templates/DamageReportInRepairFormAutocompleter';
import { openConfirmDialog } from '../../commons/components/modal/confirmModalDucks';
import { REPAIR_WRITE } from '../../commons/security/userRoles';
import { loadTemplates } from '../../commons/templates/templatesDucks';
import workshopCache from '../../commons/templates/workshopCache';

type RepairDetailsProps = InjectedFormProps<RepairFormValues>;

const RepairDetails = ({ handleSubmit, initialValues, change }: RepairDetailsProps) => {
  const dispatch = useAppDispatch();

  const roles = useAppSelector(selectSecurityContextRoles);
  const damageReportOptions = useSelector(selectRepair).damageReportOptions;
  const formValues = useAppSelector(getFormValues('repairDetailsForm')) as RepairFormValues;

  const [startDate, setStartDate] = useState(initialValues.repairStartDate);
  const [endDate, setEndDate] = useState(initialValues.repairEndDate);
  const [duration, setDuration] = useState(0);

  const canEditRepair = roles.includes(REPAIR_WRITE);
  const forCreation = initialValues.id === null;
  const readOnly = !canEditRepair || initialValues.status === 'DONE';

  useEffect(() => {
    setDuration(startDate ? -1 * startDate.diff(endDate ?? moment(), 'day') : 0);
  }, [startDate, endDate]);

  const getTitle = () => {
    if (readOnly) {
      return `Détails de la réparation n° ${initialValues.repairNumber}`;
    } else {
      return forCreation ? 'Ajouter une réparation' : `Modifier la réparation n° ${initialValues.repairNumber}`;
    }
  };

  const openDamageReportPage = (damageReportId: string) => {
    browserHistory.push(`/damage-reports/${damageReportId}`);
  };

  const updateWagonTemplate = (_data: RepairFormValues, propertyToUpdate: Partial<RepairFormValues>) => async () => {
    // update initialFormValues
    change('wagonTemplate', propertyToUpdate.wagonTemplate?.registration ? propertyToUpdate.wagonTemplate : undefined);
  };

  const updateDamageReport = (_data: RepairFormValues, propertyToUpdate: Partial<RepairFormValues>) => async () => {
    var matchedWagonTemplate = await wagonCache
      .findAllItems()
      .then((wagonTemplates) =>
        wagonTemplates.find((w: WagonTemplate) => w.registration === propertyToUpdate.damageReport?.wagonRegistration),
      );
    change('damageReport', propertyToUpdate.damageReport);
    change('wagonTemplate', matchedWagonTemplate);
  };

  const updateMarket = (_data: RepairFormValues, propertyToUpdate: any) => async () => {
    if (Object.hasOwnProperty.call(propertyToUpdate, 'market')) {
      change('market', {
        id: propertyToUpdate.market.id,
        name: propertyToUpdate.market.name + ' - ' + propertyToUpdate.market.axe,
      });
    }
  };

  const updateWorkshop = (_data: RepairFormValues, propertyToUpdate: any) => async () => {
    if (Object.hasOwnProperty.call(propertyToUpdate, 'workshop.name')) {
      const workshopName = propertyToUpdate['workshop.name'];
      const foundWorkshop = await workshopCache
        .findAllItems()
        .then((items) => items.find((i) => i.name.toUpperCase() === workshopName.toUpperCase()));

      change('workshop', foundWorkshop ? foundWorkshop : { id: null, name: workshopName.toUpperCase() });
    }
  };

  const submitAction = (updatedRepair: RepairFormValues) => {
    dispatch(persistRepairStart(updatedRepair, initialValues.id));
    if ((updatedRepair.repairStartDate && updatedRepair.repairEndDate) || updatedRepair.workshop?.id === null) {
      // if the repair is finished with a endDate, we need to refresh data in cache
      dispatch(loadTemplates());
    }
  };

  return (
    <Overlay name="repair-details" title={getTitle()} closeButton>
      <form noValidate={true} className="overlay-form" onSubmit={handleSubmit(submitAction)}>
        <Field
          component={Input}
          name="wagonTemplate.registration"
          labelText="Immatriculation du wagon *"
          placeholder="Ex: 12 34 567 8901-2"
          format={(value: string) => value && Mask.REGISTRATION.format(value)}
          inputMode="numeric"
          pattern="[0-9]*"
          disabled={!forCreation || readOnly || (forCreation && initialValues.damageReport)}
          autocompleter={wagonCache}
          autocompleteFilter={(template: WagonTemplate) => !template.inProgressRepairId}
          normalize={normalizeRegistration}
          saveAction={updateWagonTemplate}
          data={initialValues}
        />

        <Field
          component={Input}
          labelText="Ref. PVCA"
          name="damageReport.damageReportNumber"
          autocompleter={new DamageReportInRepairFormAutocompleter(damageReportOptions)}
          autocompleteFilter={(dr: DamageReportSimpleView) =>
            formValues.wagonTemplate?.registration
              ? dr.wagonRegistration === formValues.wagonTemplate?.registration
              : true
          }
          data={initialValues}
          disabled={!forCreation || readOnly || (forCreation && initialValues.damageReport)}
          saveAction={updateDamageReport}
          readOnly={false}
        />

        {formValues.damageReport?.damageReportNumber && formValues.damageReport?.id && (
          <button className="btn btn-icon hyperlink" onClick={() => openDamageReportPage(formValues.damageReport!.id)}>
            Ouvrir le PVCA
          </button>
        )}

        <MarketField
          name="market.name"
          className="icon star"
          labelText="Marché *"
          placeholder="Choisir le marché"
          data={initialValues}
          disabled={readOnly}
          saveAction={updateMarket}
        />

        <div className="workshop-and-request-date">
          <WorkshopField
            name="workshop.name"
            labelText="Atelier *"
            className="icon workshop"
            placeholder="Choisir l'atelier"
            data={initialValues}
            disabled={readOnly}
            saveAction={updateWorkshop}
          />

          <DateTimeField
            name="interventionRequestDate"
            labelText="Date de demande d'intervention *"
            data={initialValues}
            disabled={readOnly}
          />
        </div>

        <div className="repair-dates">
          <DateTimeField
            name="repairStartDate"
            labelText="Date d'entrée"
            inputReadOnly={true}
            data={initialValues}
            disabled={readOnly}
            onChange={(value) => (moment.isMoment(value) ? setStartDate(value.startOf('day')) : null)}
          />

          <DateTimeField
            name="repairEndDate"
            labelText="Date de sortie"
            inputReadOnly={true}
            data={initialValues}
            disabled={readOnly}
            onChange={(value) => (moment.isMoment(value) ? setEndDate(value.startOf('day')) : null)}
          />
        </div>
        {initialValues.repairStartDate && (
          <div className="input input-label">
            <label htmlFor="duration">Durée d'immobilisation</label>
            {`${duration} ${duration > 1 ? 'jours' : 'jour'}`}
          </div>
        )}
        <Field
          component={Input}
          type="text"
          name="accidentNumber"
          labelText="Numéro sinistre"
          placeholder="Numéro sinistre"
          data={initialValues}
          disabled={readOnly}
        />
        <Field
          component={Textarea}
          type="text"
          name="comment"
          labelText="Commentaire"
          placeholder={readOnly ? initialValues.comment ?? '' : 'Saisir un commentaire ...'}
          data={initialValues}
          disabled={readOnly}
        />

        {!readOnly && (
          <div className="btn-group repair-details-form-actions">
            <button className="btn btn-success" type="submit">
              {initialValues.id ? 'Enregistrer' : 'Ajouter'}
            </button>
            <button
              className="btn btn-link"
              type="button"
              onClick={() => {
                dispatch(hideOverlay());
              }}
            >
              Annuler
            </button>
            {initialValues.id && (
              <button
                className="btn btn-icon remove"
                type="button"
                onClick={() =>
                  dispatch(
                    openConfirmDialog({
                      title: 'Souhaitez-vous supprimer cette réparation ?',
                      actionText: 'Supprimer',
                      action: deleteRepair(initialValues.id!),
                      actionClass: 'danger',
                    }),
                  )
                }
              >
                Supprimer
              </button>
            )}
          </div>
        )}
      </form>
    </Overlay>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: selectRepair(state).data!,
});

const RepairDetailsForm = reduxForm<RepairFormValues>({
  form: 'repairDetailsForm',
  asyncValidate: asyncValidateRepairDetailsForm,
  validate: validateRepairDetailsForm,
})(RepairDetails);

export default connect(mapStateToProps)(RepairDetailsForm);
