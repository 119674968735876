import React, { useEffect, useState } from 'react';
import wagonCache from '../../../templates/wagonCache';
import { WagonTemplate } from '../../../model/templates';
import DamageReportEntity from '../../../entity/DamageReportEntity';
import './wagonLabelsStyles.scss';
import { sortDamageReportLabelKey } from '../../../model/damageReportUtils';

type WagonExistingLabel = { label: string; value: string } | undefined;

type Props = {
  registration: string;
  source: 'left-panel' | 'details';
};

const WagonRegistrationLabels = ({ registration, source }: Props) => {
  const [labels, setLabels] = useState<WagonExistingLabel[]>([]);

  useEffect(() => {
    if (registration) {
      wagonCache.findItemById(registration).then((wagonTemplateFound) => {
        setLabels(wagonTemplateFound ? getSortedWagonExistingLabels(wagonTemplateFound) : []);
      });
    } else {
      setLabels([]);
    }
  }, [registration]);

  const getSortedWagonExistingLabels = (wagonTemplate: WagonTemplate): WagonExistingLabel[] => {
    const labels = [...new Set(wagonTemplate?.inProgressDamageReports?.flatMap((report) => report.labels))] ?? [];
    return labels
      .sort(sortDamageReportLabelKey)
      .map((label) => DamageReportEntity.LABELS.find((value) => value.value === label));
  };

  return (
    <div className={`${source}-vehicle-labels`}>
      {labels.map((l) => (
        <div className={`wagon-label-${l?.value}`} key={`wagon-${registration}-${l?.value}`}>
          {l?.label}
        </div>
      ))}
    </div>
  );
};

export default WagonRegistrationLabels;
