import { DateString, IdName, VersionedEntityBase } from './common';
import { Owner } from './templates';

export const DamageReportErrorLabelKey = ['ONE', 'ZERO'];

export type DamageReportLabelKey = 'ONE' | 'K' | 'M' | 'I' | 'U' | 'R_ONE' | 'ZERO' | 'TWO' | 'IS' | 'IN';

export type DamageReportStatus = 'EMPTY' | 'IN_PROGRESS' | 'SUBMITTED' | 'VALIDATED' | 'DISABLED';

export type RailCompanyType = 'EF_CUU' | 'EF' | 'ITE';

export type CauseType = 'WEAR' | 'IMPACT_ON_OPERATION' | 'THIRD_PARTY' | 'UNKNOWN' | 'MAINTENANCE';

export type AttachmentType = 'SIGNATURE' | 'CLASSIC';

export type RailCompany = Readonly<{
  type: RailCompanyType;
  name: string;
}>;

export type DamageReportWagon = Readonly<{
  registration: string | null;
  owner: Owner | null;
  railCompany: RailCompany | null;
  loaded: boolean;
}>;

export type DamageReportTrain = Readonly<{
  trainNumber: string | null;
  startDateTime: DateString | null;
  startStation: string | null;
  endStation: string | null;
}>;

export type Damage = Readonly<{
  damageTemplateId: string | null;
  comment: string | null;
}>;

export type DamageObservation = Readonly<{
  location: string | null;
  dateTime: DateString | null;
}>;

export type WagonDamages = Readonly<{
  damages: Damage[];
  comment: string[];
  observation: DamageObservation[];
}>;

export type Attachment = Readonly<{
  id: string;
  filename: string;
  contentType: string;
  uploadDate: DateString;
  type: AttachmentType;
}>;

export type ResponsibleThirdParty = Readonly<{
  name: string | null;
  address: string | null;
  signature: Attachment | null;
}>;

export type Cause = Readonly<{
  type: CauseType | null;
  responsibleThirdParty: ResponsibleThirdParty | null;
}>;

export type Labelling = Readonly<{
  labels: DamageReportLabelKey[];
  railCompany: string | null;
  dateTime: DateString;
}>;

export type DamageReport = VersionedEntityBase<string> &
  Readonly<{
    number: number;
    reportLocation: string;
    reportDateTime: DateString;
    status: DamageReportStatus;
    wagon: DamageReportWagon;
    train: DamageReportTrain;
    wagonDamages: WagonDamages;
    cause: Cause;
    labelling: Labelling;
    newLabels: DamageReportLabelKey[];
    market: IdName<number>;
    attachments: Attachment[];
    submittedDate: DateString[];
    validatedDate: DateString;
  }>;
