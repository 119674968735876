import { DBSchema, deleteDB, openDB } from 'idb';
import { CachedTrain, TrainStep } from '../model/Train';
import {
  CachedAte,
  CachedEngineTemplate,
  CachedStation,
  CachedWagonTemplate,
  DamageTemplate,
  EngineSeries,
  HazardousMaterial,
  Market,
  Owner,
  RailwayCompany,
  Workshop,
} from '../model/templates';

const DB_NAME = 'iCompoCache';
const DB_VERSION = 5;

export interface ICompoDBSchema extends DBSchema {
  train: {
    key: string;
    value: CachedTrain;
  };
  trainStep: {
    key: string;
    value: TrainStep;
  };
  market: {
    key: number;
    value: Market;
    indexes: {
      'by-name': string;
    };
  };
  station: {
    key: string;
    value: CachedStation;
    indexes: {
      'by-label': string;
    };
  };
  engineSeries: {
    key: number;
    value: EngineSeries;
    indexes: {
      'by-number': string;
    };
  };
  engine: {
    key: number;
    value: CachedEngineTemplate;
    indexes: {
      'by-number': string;
      'by-reversedNumber': string;
    };
  };
  wagon: {
    key: string;
    value: CachedWagonTemplate;
    indexes: {
      'by-reversedRegistration': string;
    };
  };
  hazmat: {
    key: number;
    value: HazardousMaterial;
    indexes: {
      'by-dangerIdentificationNumber': string;
      'by-unitedNationsCode': string;
      'by-packingGroup': string;
    };
  };
  ate: {
    key: number;
    value: CachedAte;
    indexes: {
      'by-suffix': string;
    };
  };
  damage: {
    key: string;
    value: DamageTemplate;
  };
  owner: {
    key: number;
    value: Owner;
    indexes: {
      'by-name': string;
    };
  };
  railwayCompany: {
    key: string;
    value: RailwayCompany;
  };
  workshop: {
    key: number;
    value: Workshop;
    indexes: {
      'by-name': string;
    };
  };
}

/*
 * Workaround Safari iOS/iPadOS 16 that prevents IndexedDB process to be stuck
 * after initial authentication.
 * It might work because modifying the DOM delays the database opening, but that
 * is not sure at all.
 * See https://vfli.atlassian.net/browse/ICPO-1324
 */
const p = document.createElement('p');
p.innerText = '.';
document.body.appendChild(p);

const doOpenDB = () =>
  openDB<ICompoDBSchema>(DB_NAME, DB_VERSION, {
    upgrade(db, oldVersion = 0) {
      if (oldVersion < 1) {
        db.createObjectStore('train', { keyPath: 'compositionId' });

        const marketStore = db.createObjectStore('market', { keyPath: 'id' });
        marketStore.createIndex('by-name', 'name');

        const stationStore = db.createObjectStore('station', { keyPath: 'id' });
        stationStore.createIndex('by-label', 'normalizedLabel');

        const engineSeriesStore = db.createObjectStore('engineSeries', { keyPath: 'id' });
        engineSeriesStore.createIndex('by-number', 'seriesNumber');

        const engineStore = db.createObjectStore('engine', { keyPath: 'id' });
        engineStore.createIndex('by-number', 'number');
        engineStore.createIndex('by-reversedNumber', 'reversedNumber');

        const wagonStore = db.createObjectStore('wagon', { keyPath: 'registration' });
        wagonStore.createIndex('by-reversedRegistration', 'reversedRegistration');

        const hazmatStore = db.createObjectStore('hazmat', { keyPath: 'id' });
        hazmatStore.createIndex('by-dangerIdentificationNumber', 'dangerIdentificationNumber');
        hazmatStore.createIndex('by-unitedNationsCode', 'unitedNationsCode');
        hazmatStore.createIndex('by-packingGroup', 'packingGroup');

        const ateStore = db.createObjectStore('ate', { keyPath: 'ate' });
        ateStore.createIndex('by-suffix', 'ateSuffix');

        db.createObjectStore('damage', { keyPath: 'id' });

        const ownerStore = db.createObjectStore('owner', { keyPath: 'id' });
        ownerStore.createIndex('by-name', 'name');
      }
      if (oldVersion < 2) {
        db.deleteObjectStore('train');
        db.createObjectStore('train', { keyPath: 'id' });
        db.createObjectStore('trainStep', { keyPath: 'id' });
      }
      if (oldVersion < 3) {
        db.createObjectStore('railwayCompany', { keyPath: 'id' });
      }
      if (oldVersion < 4) {
        if (db.objectStoreNames.contains('profile' as any)) {
          db.deleteObjectStore('profile' as any);
        }
      }
      if (oldVersion < 5) {
        const workshopStore = db.createObjectStore('workshop', { keyPath: 'id' });
        workshopStore.createIndex('by-name', 'name');
      }
    },
  });

export const dbPromise = doOpenDB().catch((err) => {
  if (err.name === 'VersionError') {
    console.error('Found an newer version of the IndexedDB cache database. Deleting the current version.');
    return deleteDB(DB_NAME).then(doOpenDB);
  }
  return Promise.reject(err);
});
