/**
 * Vehicles topbar component
 */
import React, { useEffect, useRef } from 'react';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import FiltersPopup from '../../../commons/components/filters/FiltersPopup';
import OwnerSelect from '../../../commons/components/owner-select/OwnerSelect';
import CheckboxFormComponent from '../../../commons/components/checkbox/CheckboxFormComponent';
import { addLibraryWagon, loadLibrary, resetLibrary } from '../vehiclesLibraryDuck';
import InputComponent from '../../../commons/components/input/Input';
import { VEHICLES_FILTERS_FORM } from './vehiclesTopBarUtils';
import ReviewButton from './ReviewButton';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { Owner } from '../../../commons/model/templates';
import { useViewportWidth } from '../../../commons/responsive/hooks';
import classNames from 'classnames';
import { Company, NoneableCompanyOptions, NoneCompanyOrALL } from '../../../commons/model/common';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import { RootState } from '../../../commons/reducers/rootReducer';
import { connect } from 'react-redux';
import { selectSecurityContext } from '../../../commons/selectors/selectors';
import ResetFiltersButton from '../../../commons/components/filters/ResetFiltersButton';

type VehiclesTopbarProps = {
  readOnly: boolean;
};

export type VehiclesFilterFormData = {
  owners: Owner[];
  showEngines: boolean;
  showWagons: boolean;
  registration: string;
  onlyWagonsToVerify: boolean;
  onlyWagonsWithPVCA: boolean;
  manager: Company | NoneCompanyOrALL | null;
};

const VehiclesTopbar = ({ readOnly }: VehiclesTopbarProps) => {
  const dispatch = useAppDispatch();
  const { isMobile, isTablet } = useViewportWidth();
  const filtersValues = useAppSelector(getFormValues(VEHICLES_FILTERS_FORM)) as any;

  const onFilter = () => dispatch(loadLibrary());

  useEffect(
    () => () => {
      dispatch(resetLibrary());
    },
    [dispatch],
  );

  const registrationDebounceTimer = useRef<number>();
  const onChangeFilterWithDebounce = () => {
    clearTimeout(registrationDebounceTimer.current);
    registrationDebounceTimer.current = window.setTimeout(() => {
      onFilter();
    }, 500);
  };

  // Wait a few ms for the new form values to be stored in the redux state.
  // Custom "onChange" is called before redux state is updated.
  const otherFieldsDebounceTimer = useRef<number>();
  const onChangeFilter = () => {
    clearTimeout(otherFieldsDebounceTimer.current);
    otherFieldsDebounceTimer.current = window.setTimeout(onFilter, 20);
  };

  const onResetForm = () => {
    dispatch(reset(VEHICLES_FILTERS_FORM));
    onFilter();
  };

  return (
    <div className="topbar">
      <h1 className="title">Véhicules</h1>
      <ReviewButton readOnly={readOnly} />
      <div className="input">
        <Field
          component={InputComponent}
          name="registration"
          placeholder={isMobile ? 'Immat.' : 'Filtrer par immat.'}
          onChange={onChangeFilterWithDebounce}
        />
      </div>
      <div className="filters">
        <FiltersPopup>
          <div className="filters-dropdown">
            <Field component={CheckboxFormComponent} label="Wagons" name="showWagons" onChange={onChangeFilter} />
            <Field component={CheckboxFormComponent} label="Locomotives" name="showEngines" onChange={onChangeFilter} />
            <Field
              component={OwnerSelect}
              id="owners"
              name="owners"
              className="icon star"
              placeholder="Détenteurs"
              onChange={onChangeFilter}
            />
            {filtersValues.showWagons && (
              <>
                <Field
                  component={CheckboxFormComponent}
                  label="Uniquement les wagons à vérifier"
                  name="onlyWagonsToVerify"
                  onChange={onChangeFilter}
                />
                <Field
                  component={CheckboxFormComponent}
                  label="Uniquement les wagons avec avarie(s)/étiquette(s)"
                  name="onlyWagonsWithPVCA"
                  onChange={onChangeFilter}
                />
                <Field
                  component={Dropdown}
                  label="Gestionnaire"
                  name="manager"
                  saveAction={() => onChangeFilter}
                  options={NoneableCompanyOptions}
                />
              </>
            )}
            <ResetFiltersButton onClick={onResetForm} />
          </div>
        </FiltersPopup>
      </div>
      {!readOnly && (
        <button
          className={classNames('btn btn-success', isMobile && 'btn-round plus')}
          onClick={() => dispatch(addLibraryWagon())}
          type="button"
        >
          {isTablet ? 'Ajouter' : 'Ajouter un wagon'}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const currentUserCompany = selectSecurityContext(state).company;
  return {
    initialValues: {
      owners: [],
      showEngines: true,
      showWagons: true,
      registration: '',
      onlyWagonsToVerify: false,
      onlyWagonsWithPVCA: false,
      manager: currentUserCompany,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm<VehiclesFilterFormData, VehiclesTopbarProps>({
    form: VEHICLES_FILTERS_FORM,
  })(VehiclesTopbar as any),
);
