import { RootState } from '../reducers/rootReducer';
import PreAdviceWagonEntity from '../entity/PreAdviceWagonEntity';
import { TrainStepDiff } from '../model/TrainDiff';

export const getSidebar = (state: RootState) => state.sidebar;

export const getToast = (state: RootState) => state.toast;

export const getDamageReports = (state: RootState) => state.damageReports;

export const getDamageReport = (state: RootState) => state?.damageReport;

export const getDamageReportPendingUploads = (state: RootState) => state?.damageReport?.pendingAttachmentUploads;

export const getPersist = (state: RootState) => state.persist;

export const getOfflineState = (state: RootState) => state.appState.offline;

export const getSentMessages = (state: RootState) => state.sentMessages;

export const getUsers = (state: RootState) => state.users;

export const getUser = (state: RootState) => state.user;

export const getProfiles = (state: RootState) => state.profiles;

export const getProfile = (state: RootState) => state.profile;

export const getVehiclesLibrary = (state: RootState) => state.vehiclesLibrary;

export const getMailingList = (state: RootState) => state.mailingList;

export const getMailingLists = (state: RootState) => state.mailingLists;

export const getOwners = (state: RootState) => state.owners;

export const getOwner = (state: RootState) => state.owner;

export const getPreAdviceSummariesState = (state: RootState) => state.preAdviceSummaries;
export const getPreAdviceSummariesIsError = (state: RootState) => getPreAdviceSummariesState(state).error;
export const getPreAdviceSummariesIsLoading = (state: RootState) => getPreAdviceSummariesState(state).loading;
export const getPreAdviceSummaries = (state: RootState) => getPreAdviceSummariesState(state).preAdviceSummaries;
export const getPreAdviceSummariesSortDefinition = (state: RootState) =>
  getPreAdviceSummariesState(state).sortDefinition;

export const getPreAdviceState = (state: RootState) => state.preAdvice;
export const getPreAdvice = (state: RootState) => getPreAdviceState(state).preAdvice;
export const getPreAdviceIsLoading = (state: RootState) => getPreAdviceState(state).loading;
// Use only with shallowEqual
export const getPreAdviceWriteableWagonNumbers = (state: RootState) => {
  const wagons: PreAdviceWagonEntity[] = getPreAdviceState(state).preAdvice?.wagons ?? [];
  return wagons.filter((wagon) => !wagon.isValidated()).map((wagon) => wagon.position);
};

export const getPreAdviceWagonState = (state: RootState) => state.preAdviceWagon;
export const getPreAdviceWagon = (state: RootState) => getPreAdviceWagonState(state).wagon;
export const getPreAdviceWagonIsLoading = (state: RootState) => getPreAdviceWagonState(state).loading;
export const getPreAdviceWagonIsError = (state: RootState) => getPreAdviceWagonState(state).error;
export const getPreAdviceWagonIsUpdating = (state: RootState) => getPreAdviceWagonState(state).updating;

/**
 * Repairs
 */
export const getRepairsState = (state: RootState) => state.repairs;
export const getRepairsIsError = (state: RootState) => getRepairsState(state).error;
export const getRepairsIsLoading = (state: RootState) => getRepairsState(state).loading;
export const getRepairs = (state: RootState) => getRepairsState(state).repairs;
export const getRepairsSortDefinition = (state: RootState) => getRepairsState(state).sortDefinition;
/**
 * Repair
 */
export const selectRepair = (state: RootState) => state.repair;

export const getCopyJourneyState = (state: RootState) => state.copyJourney;

export const getImportSourceCandidates = (state: RootState) => state.copyWagons.importSourceCandidates;

export const selectSecurityContext = (state: RootState) => state.securityContext;
export const selectSecurityContextRoles = (state: RootState) => state.securityContext?.roles ?? [];

export const selectConfirmModal = (state: RootState) => state.confirmModal;

export const selectDragAndDrop = (state: RootState) => state.dragAndDrop;

export const selectOfflineTrains = (state: RootState) => state.offlineTrains;

export const selectTrainDiffs =
  (stepId: string) =>
  (state: RootState): TrainStepDiff[] =>
    [state.offlineTrains.diffs[stepId], state.offlineTrains.diffsBuffer[stepId]].filter((diff) => diff);

export const selectTrainStep = (state: RootState) => state.trainStep;

export const selectTrain = (state: RootState) => state.train;

export const selectTrainSummaries = (state: RootState) => state.trainSummaries;

export const selectOverlay = (state: RootState) => state.overlay;

export const selectExternalDamageReport = (state: RootState) => state.externalDamageReport;
