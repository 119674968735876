/**
 * DamageReports table component
 */
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import GlobalError from '../../commons/components/GlobalError';
import { loadDamageReports, updateColumnSorting } from './damageReportsDucks';
import DamageReportEntity from '../../commons/entity/DamageReportEntity';
import './damageReportsTableStyles.scss';
import SortTableHeader from '../../commons/components/table/SortTableHeader';
import getSortFunction from '../../damage-reports/damage-reports-table/sortDamageReports';
import Mask from '../../commons/mask/Mask';
import { getDamageReports } from '../../commons/selectors/selectors';
import helpers from '../../commons/helpers/helpers';
import DamageReportTableItemLabels from './DamageReportTableItemLabels';
import warningImg from '../../commons/assets/images/warning.svg';
import ScrollableTableBody from '../../commons/components/table/ScrollableTableBody';
import moment from 'moment/moment';
import { useAppDispatch, useAppSelector } from '../../commons/store/hooks';
import { Sort } from '../../commons/model/sort';
import { DamageReport } from '../../commons/model/DamageReport';
import { DamageTemplate } from '../../commons/model/templates';
import damageCache from '../../commons/templates/damageCache';
import TableHeader from '../../commons/components/table/TableHeader';
import { getDamageReportCompanyLabel } from '../../commons/model/common';

/**
 * Return the string representation of the given date-time.
 */
const formatDateTime = (dateTime: moment.MomentInput) =>
  dateTime ? helpers.dateTimeFormat(dateTime, 'D MMM YYYY à HH:mm') : '-';

const DamageReportsTable = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadDamageReports());
  }, [dispatch]);
  const damageReports = useAppSelector(getDamageReports);
  const sortedDamageReports = useMemo(
    () => damageReports.data.sort(getSortFunction(damageReports.sortDefinition)),
    [damageReports.data, damageReports.sortDefinition],
  );
  const [damageTemplates, setDamageTemplates] = useState<DamageTemplate[]>([]);
  useEffect(() => {
    (async () => {
      if (damageReports.data.length > 0) {
        setDamageTemplates(
          await damageCache.findItemsByIds(
            damageReports.data.flatMap((report: DamageReportEntity) => report.getDamagesCodes()),
          ),
        );
      }
    })();
  }, [damageReports.data]);

  if (damageReports.error && damageReports.data.length === 0) {
    return (
      <GlobalError title="Erreur">
        Une erreur inattendue s'est produite durant la récupération de la liste des PVCA.
      </GlobalError>
    );
  }

  if (!damageReports.data || damageReports.data.length === 0) {
    return (
      <div className="maincontent">
        {!damageReports.loading && <span>Aucun PVCA ne correspond à la recherche.</span>}
      </div>
    );
  }

  const updateSort = (sort: Sort<DamageReport>) => dispatch(updateColumnSorting(sort));

  const isDamageCritical = (id: string) => {
    const damageTemplate = damageTemplates.find((dt) => dt.id === id);
    return damageTemplate?.severity === 5;
  };

  const shouldDisplayWarning = (damageReport: DamageReportEntity) =>
    damageReport.getDamagesCodes().some(isDamageCritical) ||
    damageReport.getNewLabels().includes(DamageReportEntity.LABEL_MODEL_1_VALUE);

  const toOwnerAndMarket = (item: DamageReportEntity) => {
    return [item.wagon?.owner?.name, item.market?.name].filter((str) => str !== undefined).join(' - ');
  };

  return (
    <div className="table table-damage-reports">
      <div className="table-header">
        <SortTableHeader
          title="N°"
          className="cell-damage-report-no"
          field="number"
          sort={damageReports.sortDefinition}
          onClick={updateSort}
        />
        <SortTableHeader
          title="Wagon"
          className="cell-wagon"
          field="wagon.registration"
          sort={damageReports.sortDefinition}
          onClick={updateSort}
        />
        <SortTableHeader
          title="Établissement"
          className="cell-creation-date"
          field="creationDate"
          sort={damageReports.sortDefinition}
          onClick={updateSort}
        />
        <SortTableHeader
          title="Envoi"
          className="cell-validated-date"
          field="validatedDate"
          sort={damageReports.sortDefinition}
          onClick={updateSort}
        />
        <TableHeader title="Créateur" className="cell-created-by" />
        <SortTableHeader
          title="Statut"
          className="cell-status"
          field="status"
          sort={damageReports.sortDefinition}
          onClick={updateSort}
        />
      </div>
      <ScrollableTableBody>
        {sortedDamageReports
          .filter((item: DamageReportEntity) => !item.pendingForDeletion && !item.deleted)
          .map((item: DamageReportEntity) => () => (
            <Link
              to={`/damage-reports/${item.id}`}
              key={`damage-reports-${item.id}`}
              className={classNames('table-body-row', {
                'in-progress': item.isStatusInProgress(),
                validated: item.status === 'VALIDATED' || item.status === 'CLOSED',
                submitted: item.status === 'SUBMITTED',
                disabled: item.status === 'DISABLED',
              })}
            >
              <div className="cell-damage-report-no">{item.number}</div>
              <div className="cell-wagon">
                <div className="cell-wagon-no">
                  {item.wagon && item.wagon.registration && Mask.REGISTRATION.format(item.wagon.registration)}
                </div>
              </div>
              <div className="cell-creation-date">{formatDateTime(item.creationDate)}</div>
              <div className="cell-validated-date">{formatDateTime(item.validatedDate)}</div>
              <div className="cell-created-by">{item.createdBy.name}</div>
              {shouldDisplayWarning(item) && (
                <div className="cell-warning">
                  <img src={warningImg} alt="PVCA avec code d'avarie sévère ou avec étiquette modèle 1" />
                </div>
              )}
              <div className="cell-wagon-second-line">
                <div className="cell-company">{getDamageReportCompanyLabel(item.company)}</div>
                <div>
                  {toOwnerAndMarket(item)}
                  {item.getDamagesCodes().length > 0 && (
                    <span>
                      {toOwnerAndMarket(item) !== '' && ' - '}
                      Avaries
                      {item.getDamagesCodes().map((code: string, index: number) => (
                        <span
                          key={code + index}
                          className={classNames({
                            severe: isDamageCritical(code),
                          })}
                        >
                          {` ${code}`}
                          {index < item.getDamagesCodes().length - 1 && ', '}
                        </span>
                      ))}
                    </span>
                  )}
                  <DamageReportTableItemLabels damageReport={item} />
                </div>
              </div>
            </Link>
          ))}
      </ScrollableTableBody>
    </div>
  );
};

export default DamageReportsTable;
