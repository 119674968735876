import { IdName } from '../model/common';

export const COMPOSITION_READ = 'COMPOSITION_READ';
export const COMPOSITION_WRITE = 'COMPOSITION_WRITE';
export const COMPOSITION_SEE_ALL = 'COMPOSITION_SEE_ALL';
export const COMPOSITION_VALIDATE_WAGONS_ON_COPY = 'COMPOSITION_VALIDATE_WAGONS_ON_COPY';
export const DAMAGE_REPORT_VALIDATE = 'DAMAGE_REPORT_VALIDATE';
export const DAMAGE_REPORT_WRITE = 'DAMAGE_REPORT_WRITE';
export const ADMIN_SENT_MESSAGES = 'ADMIN_SENT_MESSAGES';
export const USER_READ = 'USER_READ';
export const USER_WRITE = 'USER_WRITE';
export const PROFILE_READ = 'PROFILE_READ';
export const PROFILE_WRITE = 'PROFILE_WRITE';
export const LIBRARY_READ = 'LIBRARY_READ';
export const LIBRARY_WRITE = 'LIBRARY_WRITE';
export const MAILING_LIST_READ = 'MAILING_LIST_READ';
export const MAILING_LIST_WRITE = 'MAILING_LIST_WRITE';
export const OWNER_READ = 'OWNER_READ';
export const OWNER_WRITE = 'OWNER_WRITE';
export const PRE_ADVICE_READ = 'PRE_ADVICE_READ';
export const PRE_ADVICE_WRITE = 'PRE_ADVICE_WRITE';
export const BRAKING_BULLETIN = 'BRAKING_BULLETIN';
export const OVERRIDE_INDICE_COMPOSITION = 'OVERRIDE_INDICE_COMPOSITION';
export const REPAIR_READ = 'REPAIR_READ';
export const REPAIR_WRITE = 'REPAIR_WRITE';

export type RoleId =
  | typeof COMPOSITION_READ
  | typeof COMPOSITION_WRITE
  | typeof COMPOSITION_SEE_ALL
  | typeof COMPOSITION_VALIDATE_WAGONS_ON_COPY
  | typeof DAMAGE_REPORT_VALIDATE
  | typeof DAMAGE_REPORT_WRITE
  | typeof ADMIN_SENT_MESSAGES
  | typeof USER_READ
  | typeof USER_WRITE
  | typeof PROFILE_READ
  | typeof PROFILE_WRITE
  | typeof LIBRARY_READ
  | typeof LIBRARY_WRITE
  | typeof MAILING_LIST_READ
  | typeof MAILING_LIST_WRITE
  | typeof OWNER_READ
  | typeof OWNER_WRITE
  | typeof PRE_ADVICE_READ
  | typeof PRE_ADVICE_WRITE
  | typeof BRAKING_BULLETIN
  | typeof OVERRIDE_INDICE_COMPOSITION
  | typeof REPAIR_READ
  | typeof REPAIR_WRITE;

export type Role = IdName<RoleId>;

const roles: Record<RoleId, Role> = {
  COMPOSITION_READ: { id: COMPOSITION_READ, name: 'Voir les compositions' },
  COMPOSITION_WRITE: { id: COMPOSITION_WRITE, name: 'Éditer les compositions' },
  COMPOSITION_SEE_ALL: { id: COMPOSITION_SEE_ALL, name: 'Accéder à tous les marchés' },
  COMPOSITION_VALIDATE_WAGONS_ON_COPY: {
    id: COMPOSITION_VALIDATE_WAGONS_ON_COPY,
    name: 'Valider les wagons lors de la copie',
  },
  PRE_ADVICE_READ: { id: PRE_ADVICE_READ, name: 'Voir les pré-annonces' },
  PRE_ADVICE_WRITE: { id: PRE_ADVICE_WRITE, name: 'Éditer les pré-annonces' },
  DAMAGE_REPORT_VALIDATE: { id: DAMAGE_REPORT_VALIDATE, name: 'Valider les PVCA' },
  DAMAGE_REPORT_WRITE: { id: DAMAGE_REPORT_WRITE, name: 'Éditer les PVCA' },
  ADMIN_SENT_MESSAGES: { id: ADMIN_SENT_MESSAGES, name: 'Piloter les envois de messages' },
  USER_READ: { id: USER_READ, name: 'Voir les utilisateurs' },
  USER_WRITE: { id: USER_WRITE, name: 'Éditer les utilisateurs' },
  PROFILE_READ: { id: PROFILE_READ, name: 'Voir les profils' },
  PROFILE_WRITE: { id: PROFILE_WRITE, name: 'Éditer les profils' },
  LIBRARY_READ: { id: LIBRARY_READ, name: 'Voir la bibliothèque' },
  LIBRARY_WRITE: { id: LIBRARY_WRITE, name: 'Éditer la bibliothèque' },
  OWNER_READ: { id: OWNER_READ, name: 'Voir les détenteurs' },
  OWNER_WRITE: { id: OWNER_WRITE, name: 'Éditer les détenteurs' },
  MAILING_LIST_READ: { id: MAILING_LIST_READ, name: 'Voir les listes de diffusion' },
  MAILING_LIST_WRITE: { id: MAILING_LIST_WRITE, name: 'Éditer les listes de diffusion' },
  BRAKING_BULLETIN: { id: BRAKING_BULLETIN, name: 'Bulletins de freinage et Traçabilité' },
  OVERRIDE_INDICE_COMPOSITION: {
    id: OVERRIDE_INDICE_COMPOSITION,
    name: 'Modifier l’indice de composition des bulletins de freinage',
  },
  REPAIR_READ: { id: REPAIR_READ, name: 'Suivre les réparations' },
  REPAIR_WRITE: { id: REPAIR_WRITE, name: 'Créer/Modifier une réparation' },
};

export default roles;
