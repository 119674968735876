import moment, { isMoment } from 'moment';
import { initialize } from 'redux-form';
import apiHelper from '../api/apiHelper';
import { Sort, SortDirection } from '../commons/model/sort';
import getRepairSortFunction from './sortRepairs';
import helpers from '../commons/helpers/helpers';
import {
  RepairListView,
  RepairsFiltersFormValues,
  RepairsFiltersQueryParams,
  RepairSortDefinition,
  RepairStatus,
} from './repairTypes';
import { AppAction, AppDispatch } from '../commons/store/store';
import { RootState } from '../commons/reducers/rootReducer';
import { ActionCreator, AnyAction } from 'redux';
/*
 * ACTIONS
 */
const START_GET_REPAIRS = 'START_GET_REPAIRS';
const SUCCESS_GET_REPAIRS = 'SUCCESS_GET_REPAIRS';
const ERROR_GET_REPAIRS = 'ERROR_GET_REPAIRS';
const UPDATE_SORT_REPAIRS = 'UPDATE_SORT_REPAIRS';
const UPDATE_REPAIRS_FILTERS = 'UPDATE_REPAIRS_FILTERS';

/*
 * ACTION CREATORS
 */
const startGetRepairs = () => ({
  type: START_GET_REPAIRS,
});

const successGetRepairs = (repairs: RepairListView[]) => ({
  type: SUCCESS_GET_REPAIRS,
  payload: { repairs },
});

const errorGetRepairs = () => ({
  type: ERROR_GET_REPAIRS,
});

export const requestGetRepairs = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(startGetRepairs());
  const filters = getState().repairs.filters;
  try {
    const repairs = await apiHelper.get('/api/repairs/list', repairsFiltersToQueryParams(filters));
    dispatch(successGetRepairs(repairs));
  } catch {
    dispatch(errorGetRepairs());
  }
};

export const requestUpdateRepairSort = (newSort: Sort<RepairListView>) => ({
  type: UPDATE_SORT_REPAIRS,
  payload: { sortDefinition: newSort },
});

export const requestUpdateRepairFilters: ActionCreator<AppAction> =
  (data, propertyToUpdate) => (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_REPAIRS_FILTERS,
      payload: { propertyToUpdate },
    });
    dispatch(requestGetRepairs());
  };

export const requestResetFilters = () => (dispatch: AppDispatch) => {
  dispatch(initialize('repairsFiltersForm', defaultFilters));
  dispatch(requestUpdateRepairFilters(null, defaultFilters));
};

/*
 * REDUCER
 */
const defaultFilters: RepairsFiltersFormValues = {
  fromRepairStartDate: null,
  toRepairStartDate: null,
  fromRepairEndDate: null,
  toRepairEndDate: null,
  repairNumberSearch: null,
  damageReportNumberSearch: null,
  wagonRegistrationNumberSearch: null,
  markets: [],
  market: null,
  workshopId: null,
  accidentNumberSearch: null,
  statusInProgress: true,
  statusDone: true,
};

const initialState: RepairsState = {
  repairs: [],
  loading: false,
  error: false,
  sortDefinition: {
    field: RepairSortDefinition.UPDATED_DATE_TIME,
    direction: SortDirection.DESC,
  },
  filters: defaultFilters,
};

export type RepairsState = {
  repairs: RepairListView[];
  loading: boolean;
  error: boolean;
  sortDefinition: {
    field: RepairSortDefinition;
    direction: SortDirection;
  };
  filters: RepairsFiltersFormValues;
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_GET_REPAIRS:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ERROR_GET_REPAIRS:
      return {
        ...initialState,
        filters: state.filters,
        sortDefinition: state.sortDefinition,
        error: true,
      };
    case SUCCESS_GET_REPAIRS: {
      action.payload.repairs.sort(getRepairSortFunction(state.sortDefinition));
      return {
        ...state,
        loading: false,
        error: false,
        repairs: action.payload.repairs,
      };
    }
    case UPDATE_SORT_REPAIRS: {
      const repairs = [...state.repairs];
      repairs.sort(getRepairSortFunction(action.payload.sortDefinition));
      return {
        ...state,
        repairs: repairs,
        sortDefinition: action.payload.sortDefinition,
      };
    }
    case UPDATE_REPAIRS_FILTERS: {
      let newFilters: RepairsFiltersFormValues = {
        ...state.filters,
      };
      const filtersToUpdate = action.payload.propertyToUpdate;
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'fromRepairStartDate')) {
        newFilters = {
          ...newFilters,
          fromRepairStartDate: filtersToUpdate.fromRepairStartDate ? moment(filtersToUpdate.fromRepairStartDate) : null,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'toRepairStartDate')) {
        newFilters = {
          ...newFilters,
          toRepairStartDate: filtersToUpdate.toRepairStartDate ? moment(filtersToUpdate.toRepairStartDate) : null,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'fromRepairEndDate')) {
        newFilters = {
          ...newFilters,
          fromRepairEndDate: filtersToUpdate.fromRepairEndDate ? moment(filtersToUpdate.fromRepairEndDate) : null,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'toRepairEndDate')) {
        newFilters = {
          ...newFilters,
          toRepairEndDate: filtersToUpdate.toRepairEndDate ? moment(filtersToUpdate.toRepairEndDate) : null,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'markets')) {
        newFilters = {
          ...newFilters,
          markets: filtersToUpdate.markets,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'workshopId')) {
        newFilters = {
          ...newFilters,
          workshopId: filtersToUpdate.workshopId,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'accidentNumberSearch')) {
        newFilters = {
          ...newFilters,
          accidentNumberSearch: helpers.trimBadSpaces(filtersToUpdate.accidentNumberSearch),
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'repairNumberSearch')) {
        newFilters = {
          ...newFilters,
          repairNumberSearch: filtersToUpdate.repairNumberSearch,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'damageReportNumberSearch')) {
        newFilters = {
          ...newFilters,
          damageReportNumberSearch: filtersToUpdate.damageReportNumberSearch,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'wagonRegistrationNumberSearch')) {
        newFilters = {
          ...newFilters,
          wagonRegistrationNumberSearch: filtersToUpdate.wagonRegistrationNumberSearch
            ? helpers.registrationUnformat(filtersToUpdate.wagonRegistrationNumberSearch)
            : filtersToUpdate.wagonRegistrationNumberSearch,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'statusInProgress')) {
        newFilters = {
          ...newFilters,
          statusInProgress: filtersToUpdate.statusInProgress,
        };
      }
      if (Object.prototype.hasOwnProperty.call(filtersToUpdate, 'statusDone')) {
        newFilters = {
          ...newFilters,
          statusDone: filtersToUpdate.statusDone,
        };
      }

      return {
        ...state,
        filters: newFilters,
      };
    }
    default:
      return state;
  }
};

/*
 * Utilities
 */
export const repairsFiltersToQueryParams = (filters: RepairsFiltersFormValues) => {
  let statuses: RepairStatus[] = [];
  if (filters.statusInProgress) {
    statuses = statuses.concat('IN_PROGRESS');
  }
  if (filters.statusDone) {
    statuses = statuses.concat('DONE');
  }
  const params: RepairsFiltersQueryParams = {
    wagonRegistrationNumberSearch:
      filters.wagonRegistrationNumberSearch !== '' ? filters.wagonRegistrationNumberSearch : null,
    repairNumberSearch: filters.repairNumberSearch ?? null,
    damageReportNumberSearch: filters.damageReportNumberSearch ?? null,
    accidentNumberSearch: filters.accidentNumberSearch !== '' ? filters.accidentNumberSearch : null,
    fromRepairStartDate: filters.fromRepairStartDate
      ? moment(filters.fromRepairStartDate).startOf('day').local().format()
      : null,
    toRepairStartDate: filters.toRepairStartDate
      ? moment(filters.toRepairStartDate).startOf('day').add(1, 'day').local().format()
      : null,
    fromRepairEndDate:
      filters.fromRepairEndDate && isMoment(filters.fromRepairEndDate) && filters.fromRepairEndDate.isValid()
        ? moment(filters.fromRepairEndDate).startOf('day').local().format()
        : null,
    toRepairEndDate:
      filters.toRepairEndDate && isMoment(filters.toRepairEndDate) && filters.toRepairEndDate.isValid()
        ? moment(filters.toRepairEndDate).startOf('day').add(1, 'day').local().format()
        : null,
    marketIds: filters.markets && filters.markets.length > 0 ? filters.markets.map((m) => m.id).join(', ') : null,
    workshopId: filters.workshopId ?? null,
    statuses: statuses.join(', '),
  };

  return params;
};
