import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OverlayName =
  | 'mailing-list'
  | 'owner'
  | 'user'
  | 'profile'
  | 'vehicle-library'
  | 'copy-wagons'
  | 'braking-bulletin'
  | 'traceability'
  | 'repair-details';

export type OverlayState = {
  active: OverlayName | null;
};

const initialState: OverlayState = {
  active: null,
};

const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    showOverlay: (state, { payload }: PayloadAction<OverlayName>) => {
      state.active = payload;
    },
    hideOverlay: (state) => {
      state.active = null;
    },
  },
});

export const { showOverlay, hideOverlay } = overlaySlice.actions;
export default overlaySlice.reducer;
