import { selectExternalDamageReport } from '../../../../commons/selectors/selectors';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import DateTimeField from '../../../../commons/components/input/DateTimeField';
import React from 'react';
import DamageReportEntity from '../../../../commons/entity/DamageReportEntity';
import MultiCheckboxFormComponent from '../../../../commons/components/checkbox/MultiCheckboxFormComponent';
import './WagonExternalDamageReportForm.scss';
import { useAppDispatch, useAppSelector } from '../../../../commons/store/hooks';
import {
  closeExternalDamageReportDialog,
  ExternalDamageReportModalFormValues,
  submitExternalDamageReportDialog,
} from './wagonExternalDucks';
import { RootState } from '../../../../commons/reducers/rootReducer';
import classNames from 'classnames';
import Damages from '../../../../commons/components/damage/Damages';
import { validate } from './externalDamageReportValidationUtils';

const WagonExternalDamageReportFields = ({ handleSubmit }: InjectedFormProps<ExternalDamageReportModalFormValues>) => {
  const dispatch = useAppDispatch();
  const { damages, labels, validatedDate, onCancel } = useAppSelector(selectExternalDamageReport);

  const handleCancel = () => {
    onCancel?.();
    dispatch(closeExternalDamageReportDialog());
  };

  return (
    <form onSubmit={handleSubmit((formValues) => dispatch(submitExternalDamageReportDialog(formValues)))}>
      <div className="modal external-damage-report-form-modal">
        <div className="modal-fade" onClick={handleCancel} />
        <div className="modal-body" style={{ width: '80%', maxWidth: '1000px', minWidth: '525px' }}>
          <div style={{ margin: 20 }}>
            <div className={classNames('form-section')}>
              <div className="fields">
                <div className="title">Dommages</div>
                <div className="full-width">
                  <FieldArray
                    name="damages"
                    canLabelBeChanged={true}
                    component={Damages}
                    allowIntermediary={true}
                    minDamages={1}
                    maxDamages={3}
                    data={damages}
                  />
                </div>
              </div>
            </div>
            <div className={classNames('form-section')}>
              <div className="fields">
                <label>Étiquettes *</label>
                <div className="full-width">
                  <Field
                    name="labels"
                    labelText=""
                    data={labels}
                    component={MultiCheckboxFormComponent}
                    options={DamageReportEntity.LABELS.filter((l) => !['IS', 'IN'].includes(l.value))}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'column', justifyItems: 'start' }}>
              <label>Date de validation *</label>
              <DateTimeField utc inputReadOnly data={validatedDate} name="validatedDate" />
            </div>
            <div className="btn-group" style={{ paddingTop: 10, display: 'flex', justifyContent: 'end' }}>
              <button className="btn btn-link" type="button" onClick={handleCancel}>
                Annuler
              </button>
              <button className="btn btn-success" type="submit">
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const WagonExternalDamageReportForm = reduxForm<ExternalDamageReportModalFormValues>({
  form: 'inputExternalDamageReportModal',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(WagonExternalDamageReportFields);

const mapStateToProps = (state: RootState) => ({
  initialValues: selectExternalDamageReport(state),
});

export default connect(mapStateToProps)(WagonExternalDamageReportForm);
