import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import '../../commons/components/filters/filtersStyles.scss';
import './repairsFiltersStyles.scss';
import classNames from 'classnames';
import WorkshopField from '../../commons/components/input/WorkshopField';
import { useViewportWidth } from '../../commons/responsive/hooks';
import Input from '../../commons/components/input/Input';
import { requestResetFilters, requestUpdateRepairFilters } from '../RepairsDucks';
import DateIntervalFilters from '../../commons/components/filters/DateIntervalFilters';
import ResetFiltersButton from '../../commons/components/filters/ResetFiltersButton';
import MarketSelect from '../../commons/components/market-select/MarketSelect';
import CheckButton from '../../commons/components/check-button/CheckButton';
import helpers from '../../commons/helpers/helpers';
import { RepairsFiltersFormValues } from '../repairTypes';
import { useAppDispatch } from '../../commons/store/hooks';
import { getRepairsState } from '../../commons/selectors/selectors';

type Props = InjectedFormProps<RepairsFiltersFormValues>;

const RepairsFilters = ({ change, handleSubmit }: Props) => {
  const [isOpened, setOpenStatus] = useState(false);
  const { isMobile } = useViewportWidth();
  const dispatch = useAppDispatch();
  const { filters } = useSelector(getRepairsState);
  const resetFilters = () => dispatch(requestResetFilters());

  return (
    <div className="filters">
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data, propertyToUpdate) => {
          dispatch(requestUpdateRepairFilters(data, propertyToUpdate));
        })}
      >
        <Field
          component={Input}
          name="repairNumberSearch"
          placeholder={isMobile ? 'n° réparation' : 'Filtrer par n° réparation'}
          pattern="[0-9]*" // iPad numeric keyboard
          inputMode="numeric"
          onChange={(_e, repairNumberSearch) => {
            if (!repairNumberSearch || helpers.trimBadSpaces(repairNumberSearch).length > 0) {
              dispatch(requestUpdateRepairFilters(filters, { repairNumberSearch }));
            }
          }}
        />
        <div>
          <button
            className={classNames('btn filter', isMobile ? 'btn-round' : 'btn-link btn-icon')}
            type="button"
            onClick={() => setOpenStatus(true)}
          >
            Autres filtres
          </button>
          {isOpened && (
            <div className="overlay">
              <div className="overlay-fade" onClick={() => setOpenStatus(false)} />
              <div className="filters-dropdown">
                <Field
                  component={Input}
                  name="wagonRegistrationNumberSearch"
                  placeholder={isMobile ? 'Imma. de wagon' : 'Immatriculation de wagon'}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onChange={(_e, wagonRegistrationNumberSearch) => {
                    if (
                      !wagonRegistrationNumberSearch ||
                      helpers.trimBadSpaces(wagonRegistrationNumberSearch).length > 0
                    ) {
                      dispatch(requestUpdateRepairFilters(filters, { wagonRegistrationNumberSearch }));
                    }
                  }}
                />
                <Field
                  component={Input}
                  name="damageReportNumberSearch"
                  placeholder={'N° PVCA'}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onChange={(_e, damageReportNumberSearch) => {
                    if (!damageReportNumberSearch || helpers.trimBadSpaces(damageReportNumberSearch).length > 0) {
                      dispatch(requestUpdateRepairFilters(filters, { damageReportNumberSearch }));
                    }
                  }}
                />
                <div className="date-range-filters">
                  <div className="label">Date d'entrée</div>
                  <DateIntervalFilters
                    changeFormValue={change}
                    currentFromDateValue={filters.fromRepairStartDate}
                    currentToDateValue={filters.toRepairStartDate}
                    fromDateFieldName="fromRepairStartDate"
                    toDateFieldName="toRepairStartDate"
                    offline={false}
                    reloadListAction={requestUpdateRepairFilters}
                    onlyPassedDates={true}
                  />
                </div>
                <div className="date-range-filters">
                  <div className="label">Date de sortie</div>
                  <DateIntervalFilters
                    changeFormValue={change}
                    currentFromDateValue={filters.fromRepairEndDate}
                    currentToDateValue={filters.toRepairEndDate}
                    fromDateFieldName="fromRepairEndDate"
                    toDateFieldName="toRepairEndDate"
                    offline={false}
                    reloadListAction={requestUpdateRepairFilters}
                    onlyPassedDates={true}
                  />
                </div>
                <Field
                  component={MarketSelect}
                  id="markets"
                  name="markets"
                  className="icon star"
                  placeholder="Marché"
                  saveAction={requestUpdateRepairFilters}
                />
                <WorkshopField
                  name="workshopId"
                  className="icon workshop"
                  placeholder="Atelier"
                  saveAction={requestUpdateRepairFilters}
                />
                <div className="btn-group">
                  <Field
                    component={CheckButton}
                    id="filter-status-in-progress"
                    name="statusInProgress"
                    className="btn state-1"
                    label="En cours"
                    saveAction={requestUpdateRepairFilters}
                    checkValue={filters.statusInProgress}
                  />
                  <Field
                    component={CheckButton}
                    id="filter-status-done"
                    name="statusDone"
                    className="btn state-3"
                    label="Terminé"
                    saveAction={requestUpdateRepairFilters}
                    checkValue={filters.statusDone}
                  />
                </div>
                <Field
                  component={Input}
                  name="accidentNumberSearch"
                  placeholder={isMobile ? 'N° sinistre' : 'Numéro de sinistre'}
                  onChange={(_e, accidentNumberSearch) => {
                    if (!accidentNumberSearch || helpers.trimBadSpaces(accidentNumberSearch).length > 0) {
                      dispatch(requestUpdateRepairFilters(filters, { accidentNumberSearch }));
                    }
                  }}
                />
                <ResetFiltersButton onClick={resetFilters} />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const FiltersForm = reduxForm<RepairsFiltersFormValues>({
  form: 'repairsFiltersForm',
})(RepairsFilters);

export default connect()(FiltersForm as any);
