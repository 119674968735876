import React from 'react';
import moment, { Duration, Moment, MomentInput } from 'moment';
import DateTimeField from '../input/DateTimeField';
import { AppAction } from '../../store/store';
import { useAppDispatch } from '../../store/hooks';

type DateIntervalFiltersProps = {
  changeFormValue: (field: string, value: any) => void;
  /**
   * The date currently selected in the "fromDate" field.
   * There must be a value if maxIntervalWidth value is passed to the component.
   */
  currentFromDateValue: Date | Moment | string | null;
  /**
   * The date currently selected in the "toDate" field.
   * There must be a value if maxIntervalWidth value is passed to the component.
   */
  currentToDateValue: Date | Moment | string | null;
  fromDateFieldName?: string;
  toDateFieldName?: string;
  maxIntervalWidth?: Duration;
  offline: boolean;
  reloadListAction: (data: any, propertyToUpdate: any) => AppAction;
  /**
   * if true, use can only select both passed dates
   */
  onlyPassedDates?: boolean;
};

const DateIntervalFilters = ({
  changeFormValue,
  currentFromDateValue,
  currentToDateValue,
  fromDateFieldName = 'fromDate',
  toDateFieldName = 'toDate',
  maxIntervalWidth,
  offline,
  reloadListAction,
  onlyPassedDates = false,
}: DateIntervalFiltersProps) => {
  const dispatch = useAppDispatch();

  const isFromDateValid = (date: Moment | null): boolean => {
    if (!maxIntervalWidth) {
      return Boolean(!onlyPassedDates || (date && date.isSameOrBefore(moment())));
    } else {
      if (onlyPassedDates) {
        return Boolean(
          date &&
            date.isSameOrAfter(moment(currentToDateValue).subtract(maxIntervalWidth)) &&
            date.isSameOrBefore(moment()),
        );
      }
      return Boolean(date && date.isSameOrAfter(moment(currentToDateValue).subtract(maxIntervalWidth)));
    }
  };

  const isToDateValid = (date: Moment | null): boolean => {
    if (!maxIntervalWidth) {
      return Boolean(!onlyPassedDates || (date && date.isSameOrBefore(moment())));
    } else {
      if (onlyPassedDates) {
        return Boolean(
          date &&
            date.isSameOrAfter(moment(currentFromDateValue).subtract(maxIntervalWidth)) &&
            date.isSameOrBefore(moment()),
        );
      }
      return Boolean(date && date.isSameOrAfter(moment(currentFromDateValue).subtract(maxIntervalWidth)));
    }
  };

  const fromDateChangedHandler = (data: any, propertyToUpdate: any) => {
    const reloadListCommand: any = { ...propertyToUpdate };
    if (
      currentToDateValue &&
      moment(currentToDateValue).isBefore(moment(propertyToUpdate[fromDateFieldName] as MomentInput))
    ) {
      changeFormValue(toDateFieldName, propertyToUpdate[fromDateFieldName]);
      reloadListCommand[toDateFieldName] = propertyToUpdate[fromDateFieldName];
    }

    return reloadListAction(data, reloadListCommand);
  };

  const toDateChangedHandler = (data: any, propertyToUpdate: any) => {
    const reloadListCommand: any = { ...propertyToUpdate };
    if (
      currentFromDateValue &&
      moment(currentFromDateValue).isAfter(moment(propertyToUpdate[toDateFieldName] as MomentInput))
    ) {
      changeFormValue(fromDateFieldName, propertyToUpdate[toDateFieldName]);
      reloadListCommand[fromDateFieldName] = propertyToUpdate[toDateFieldName];
    }

    return reloadListAction(data, reloadListCommand);
  };

  const todayChangedHandler = () => {
    const today = moment().startOf('day');
    const reloadListCommand = {
      [fromDateFieldName]: today,
      [toDateFieldName]: today,
    };
    changeFormValue(fromDateFieldName, today);
    changeFormValue(toDateFieldName, today);
    return dispatch(reloadListAction({}, reloadListCommand));
  };

  return (
    <div className="date-interval">
      <div className="dates">
        <div className="date">
          <div className="date-label">Du</div>
          <DateTimeField
            name={fromDateFieldName}
            disabled={offline}
            inputReadOnly={true}
            placeholder=""
            saveAction={fromDateChangedHandler}
            isValidDate={isFromDateValid}
            data={{}}
          />
        </div>
        <div className="date">
          <div className="date-label">Au</div>
          <DateTimeField
            name={toDateFieldName}
            disabled={offline}
            inputReadOnly={true}
            placeholder=""
            saveAction={toDateChangedHandler}
            isValidDate={isToDateValid}
            data={{}}
          />
        </div>
      </div>
      <button className="btn btn-basic" onClick={todayChangedHandler} type="button">
        <div>Aujourd'hui</div>
      </button>
    </div>
  );
};

export default DateIntervalFilters;
