import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

type AutocompleteProps<T> = {
  value: string;
  autocomplete: (value: string) => Promise<T[]>;
  formatItem?: (item: T) => string;
  onClick?: (item: T) => any;
  onScroll?: () => any;
  active?: boolean;
  allowManualValues?: boolean;
  formatManualValue?: (value: string) => T;
};

const Autocomplete = <T,>({
  value,
  autocomplete,
  formatItem,
  onClick,
  onScroll,
  active,
  allowManualValues = false,
  formatManualValue,
}: AutocompleteProps<T>) => {
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<T[] | null>(null);

  useEffect(() => {
    if (active) {
      (async () => {
        setDisplay(true);
        setLoading(true);
        setValues(null);
        const result = await autocomplete(value);
        setLoading(false);
        setValues(result);
      })().then();
    }
  }, [autocomplete, value, active]);

  if (!display || !active) {
    return null;
  }

  const renderValue = (item: T, i: number) => (
    <div
      key={`suggestion-${i}`}
      className="dropdown-option"
      onMouseDown={() => {
        setDisplay(false);
        if (onClick) {
          onClick(item);
        }
        return false;
      }}
    >
      {formatItem ? formatItem(item) : `${item}`}
    </div>
  );

  return (
    <div id="autocomplete" className="dropdown-container">
      <div
        className={classNames('dropdown-body', { 'loader small': loading })}
        onMouseDown={() => {
          // Prevent focus stealing
          if (onScroll) {
            onScroll();
          }
          return false;
        }}
      >
        {values?.length
          ? values.map(renderValue)
          : allowManualValues && formatManualValue && value
          ? renderValue(formatManualValue(value), 0)
          : values && value && <div className="dropdown-option">Aucun résultat</div>}
      </div>
    </div>
  );
};

export default Autocomplete;
