import React from 'react';
import SortTableHeader from '../../commons/components/table/SortTableHeader';
import { RepairListView } from '../repairTypes';
import { Sort } from '../../commons/model/sort';

type Props = {
  sortDefinition: Sort<RepairListView>;
  updateSort: (sort: Sort<RepairListView>) => void;
};
const RepairsTableHeader = ({ sortDefinition, updateSort }: Props) => (
  <div className="table-header">
    <SortTableHeader<RepairListView>
      title="N° de réparation"
      className="cell-repair-number"
      field="repairNumber"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <SortTableHeader<RepairListView>
      title="Immatriculation de wagon"
      className="cell-wagon-registration-number"
      field="wagonRegistrationNumber"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <div className="cell-damage-report-number">Ref. PVCA</div>
    <SortTableHeader<RepairListView>
      title="Date de DI"
      className="cell-intervention_request_date"
      field="interventionRequestDate"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <SortTableHeader<RepairListView>
      title="Date d'entrée"
      className="cell-start-date"
      field="repairStartDate"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <SortTableHeader<RepairListView>
      title="Date de sortie"
      className="cell-end-date"
      field="repairEndDate"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <SortTableHeader<RepairListView>
      title="Durée d'immobilisation"
      className="cell-duration"
      field="duration"
      sort={sortDefinition}
      onClick={updateSort}
    />
    <div className="cell-market-name">Marché</div>
    <div className="cell-market-name">Axe du marché</div>
    <div className="cell-workshop-name">Atelier</div>
    <div className="cell-accident-number">Numéro sinistre</div>
  </div>
);

export default RepairsTableHeader;
