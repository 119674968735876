/**
 * Wagon details component
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, FormErrors, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import { getVehiclesLibrary, selectSecurityContextRoles } from '../../../commons/selectors/selectors';
import Overlay from '../../../commons/components/overlay/Overlay';
import Input from '../../../commons/components/input/Input';
import { cancelEditWagon, createLibraryWagon, deleteLibraryWagon, updateLibraryWagon } from '../vehiclesLibraryDuck';
import { openConfirmDialog } from '../../../commons/components/modal/confirmModalDucks';
import { addDamageReport } from '../../../damage-reports/damage-report/damageReportDucks';
import { parseToInt } from '../../../commons/redux-form/valueParser';
import {
  normalizePositiveFloatTwoDecimal,
  normalizePositiveNumber,
  normalizeRegistration,
} from '../../../commons/redux-form/valueNormalizer';
import Mask from '../../../commons/mask/Mask';
import { formatDecimalAndSeparateThousands, formatDecimalNumber } from '../../../commons/redux-form/valueFormatter';
import { DAMAGE_REPORT_WRITE, REPAIR_WRITE } from '../../../commons/security/userRoles';
import { initialFormValuesForNewWagonTemplates, validate } from './wagonTemplateUtils';

import './WagonDetailsStyles.scss';
import ownerCache from '../../../commons/templates/ownerCache';
import Dropdown from '../../../commons/components/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import { RootState } from '../../../commons/reducers/rootReducer';
import {
  WAGON_GENERAL_STATUS_LABELS,
  WagonGeneralStatus,
  WagonTemplate,
  WagonTemplateDamageReport,
} from '../../../commons/model/templates';
import CheckboxFormComponent from '../../../commons/components/checkbox/CheckboxFormComponent';
import { hideOverlay } from '../../../commons/components/overlay/overlayDucks';
import { CompanyOptions } from '../../../commons/model/common';
import { createRepair } from '../../../repairs/RepairDucks';
import { browserHistory } from '../../../commons/routes/routerHistoryConfig';
import Tooltip from 'rc-tooltip';
import WagonDamageReportList from './WagonDamageReportList';
import { DamageReportErrorLabelKey, DamageReportLabelKey } from '../../../commons/model/DamageReport';

type WagonTemplateFormData = WagonTemplate;

type WagonTemplateDetailsProps = InjectedFormProps<WagonTemplateFormData>;

const WagonTemplateDetails = ({ handleSubmit }: WagonTemplateDetailsProps) => {
  const vehiclesLibrary = useAppSelector(getVehiclesLibrary);
  const roles = useAppSelector(selectSecurityContextRoles);
  const dispatch = useAppDispatch();

  const [ownersOptions, setOwnersOptions] = useState({});

  const persistWagonTemplate = async (updatedWagon: WagonTemplate) => {
    const action = vehiclesLibrary.editedWagon ? updateLibraryWagon(updatedWagon) : createLibraryWagon(updatedWagon);
    const result = await dispatch(action);
    if (result.meta.requestStatus === 'rejected') {
      throw new SubmissionError(result.payload as FormErrors);
    }
  };

  const canCreateNewPVCA = (registration: string) => {
    if (!registration) {
      return false;
    }
    if (!vehiclesLibrary.editedWagon || vehiclesLibrary.editedWagon.inProgressDamageReports.length === 0) {
      return true;
    }
    // check existence of ONE labels on "active" damageReports
    return !vehiclesLibrary.editedWagon.inProgressDamageReports
      .flatMap((report: WagonTemplateDamageReport) => report.labels)
      .find((l: DamageReportLabelKey) => DamageReportErrorLabelKey.includes(l));
  };

  const canCreateRepair = (currentWagonTemplate: WagonTemplate) => {
    if (!currentWagonTemplate) {
      return false;
    }
    // if we want to create a repair on a wagonTemplate, it should not have a repair in progress
    return !currentWagonTemplate.inProgressRepairId;
  };

  useEffect(() => {
    (async () => {
      const owners = await ownerCache.findAllItems();

      setOwnersOptions(
        owners.reduce(
          (acc, owner) => ({
            ...acc,
            [owner.id]: owner.name,
          }),
          {},
        ),
      );
    })();
  }, []);

  const title = vehiclesLibrary.editedWagon
    ? `Modifier wagon - ${
        WAGON_GENERAL_STATUS_LABELS[vehiclesLibrary.editedWagon.wagonGeneralStatus as WagonGeneralStatus]
      }`
    : 'Ajouter un wagon';

  return (
    <Overlay name="vehicle-library" title={title} onClosed={() => dispatch(cancelEditWagon())} closeButton>
      <div className="round-btn-group">
        {roles.includes(REPAIR_WRITE) && canCreateRepair(vehiclesLibrary.editedWagon) && (
          <Tooltip overlay="Créer une réparation" placement="top" trigger={['hover', 'click']}>
            <button
              className="btn btn-round btn-new-repair"
              type="button"
              onClick={async () => {
                browserHistory.push('/repairs');
                dispatch(
                  createRepair({
                    repairDetails: null,
                    initialWagonTemplate: {
                      id: vehiclesLibrary.editedWagon.id,
                      registration: vehiclesLibrary.editedWagon.registration,
                    },
                  }),
                );
              }}
            />
          </Tooltip>
        )}

        {roles.includes(DAMAGE_REPORT_WRITE) && canCreateNewPVCA(vehiclesLibrary.editedWagon?.registration) && (
          <Tooltip overlay="Ouvrir un PVCA" placement="top" trigger={['hover', 'click']}>
            <button
              className="btn btn-round btn-new-damage-report"
              type="button"
              onClick={async () => {
                const ownerTemplate =
                  vehiclesLibrary.editedWagon?.ownerId &&
                  (await ownerCache.findItemById(vehiclesLibrary.editedWagon.ownerId));
                dispatch(addDamageReport(true, vehiclesLibrary.editedWagon, ownerTemplate));
              }}
            ></button>
          </Tooltip>
        )}
      </div>
      <form noValidate={true} className="overlay-form" onSubmit={handleSubmit(persistWagonTemplate)}>
        <Field
          disabled={vehiclesLibrary.editedWagon}
          component={Input}
          name="registration"
          labelText="Immatriculation *"
          placeholder="Ex: 12 34 567 8901-2"
          format={(value: string) => value && Mask.REGISTRATION.format(value)}
          inputMode="numeric"
          pattern="[0-9]*"
          normalize={normalizeRegistration}
        />
        <div className="inputs-group">
          <Field
            component={Input}
            name="length"
            labelText="Longueur *"
            unit="m"
            format={formatDecimalNumber}
            inputMode="numeric"
            pattern="[0-9]*"
            normalize={normalizePositiveFloatTwoDecimal}
          />
          <Field
            component={Input}
            name="tare"
            labelText="Tare *"
            unit="kg"
            format={formatDecimalAndSeparateThousands}
            inputMode="numeric"
            pattern="[0-9]*"
            normalize={normalizePositiveNumber}
            parse={parseToInt}
          />
        </div>
        <Field
          component={Input}
          name="nbAxles"
          labelText="Nombre d’essieux *"
          inputMode="numeric"
          pattern="[0-9]*"
          normalize={normalizePositiveNumber}
          parse={parseToInt}
        />
        <Field component={Dropdown} labelText="Détenteur *" name="ownerId" options={ownersOptions} readOnly={false} />
        <Field component={CheckboxFormComponent} name="doubleWagon" label="Wagon double" />
        <Field component={Dropdown} labelText="Gestionnaire" name="manager" options={CompanyOptions} />
        <WagonDamageReportList
          registration={vehiclesLibrary.editedWagon?.registration ?? ''}
          damageReports={vehiclesLibrary.editedWagon?.inProgressDamageReports ?? []}
          canOpenDamageReport={roles.includes(DAMAGE_REPORT_WRITE)}
        />
        <div className="btn-group wagon-template-form-actions">
          <button className="btn btn-success" type="submit">
            {vehiclesLibrary.editedWagon ? 'Modifier' : 'Ajouter'}
          </button>
          <button
            className="btn btn-link"
            type="button"
            onClick={() => {
              dispatch(hideOverlay());
              dispatch(cancelEditWagon());
            }}
          >
            Annuler
          </button>
          {vehiclesLibrary.editedWagon && (
            <button
              className="btn btn-icon remove"
              type="button"
              onClick={() =>
                dispatch(
                  openConfirmDialog({
                    title: 'Souhaitez-vous supprimer le modèle de wagon ?',
                    actionText: 'Supprimer',
                    action: deleteLibraryWagon(vehiclesLibrary.editedWagon),
                    actionClass: 'danger',
                  }),
                )
              }
            >
              Supprimer
            </button>
          )}
        </div>
      </form>
    </Overlay>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: getVehiclesLibrary(state).editedWagon ?? initialFormValuesForNewWagonTemplates(),
});

const WagonTemplateForm = reduxForm<WagonTemplateFormData>({
  form: 'wagonTemplateForm',
  validate,
})(WagonTemplateDetails);

export default connect(mapStateToProps)(WagonTemplateForm);
