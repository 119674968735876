import { Moment } from 'moment/moment';
import { DamageReportLabelKey } from '../../../../commons/model/DamageReport';
import { ValidationErrors } from '../../../../commons/validation/validationUtils';
import { ExternalDamageReportModalFormValues } from './wagonExternalDucks';

type ExternalDamageReportDamagesRowToValidate = { damageTemplateId: string | undefined; comment: string | undefined };

export const validate = (formValues: ExternalDamageReportModalFormValues) => {
  if (!formValues) {
    return {};
  }
  return {
    ...validateExternalDamageReportValidatedDate(formValues.validatedDate),
    ...validateExternalDamageReportDamages(formValues.damages),
    ...validateExternalDamageReportLabels(formValues.labels),
  };
};

const validateExternalDamageReportValidatedDate = (date: Moment | null): ValidationErrors<'validatedDate'> => {
  if (!date) {
    return { validatedDate: 'Champ obligatoire' };
  }
  if (date.isAfter(new Date())) {
    return { validatedDate: 'La date doit être antérieure à la date du jour' };
  }
  return {};
};

const validateExternalDamageReportDamages = (damages: ExternalDamageReportDamagesRowToValidate[] | null) => {
  const damagesErrors: { damages: ExternalDamageReportDamagesRowToValidate[] } = { damages: [] };
  damages?.forEach((damage, index) => {
    damagesErrors.damages[index] = { damageTemplateId: undefined, comment: undefined };
    if (!damage.damageTemplateId || damage.damageTemplateId.length === 0) {
      damagesErrors.damages[index].damageTemplateId = 'Toutes les avaries doivent être remplies';
    }
    if (!damage.comment || damage.comment.length === 0) {
      damagesErrors.damages[index].comment = 'Toutes les avaries doivent avoir une description';
    }
  });
  return { ...damagesErrors };
};

const validateExternalDamageReportLabels = (labels: DamageReportLabelKey[] | null): ValidationErrors<'labels'> => {
  if (!labels || labels.length <= 0) {
    return { labels: 'Vous devez sélectionner au moins une étiquette.' };
  }
  return {};
};
