import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { isValid } from 'redux-form';

import { requestValidatePreAdvice } from '../preAdviceDucks';
import { formName } from '../preAdviceFormUtils';
import PreAdviceEntity from '../../../commons/entity/PreAdviceEntity';
import { useAppDispatch, useAppSelector } from '../../../commons/store/hooks';
import wagonCache from '../../../commons/templates/wagonCache';
import { WagonTemplate } from '../../../commons/model/templates';
import PreAdviceWagonEntity from '../../../commons/entity/PreAdviceWagonEntity';
import { wagonHasRedLabel } from '../../../admin/vehicles-library/vehiclesLibraryUtils';

type PreAdviceValidationProps = {
  preAdvice: PreAdviceEntity;
};

const PreAdviceValidation = ({ preAdvice }: PreAdviceValidationProps) => {
  const dispatch = useAppDispatch();
  const formValid = useAppSelector(isValid(formName));
  const [selectedWagons, setSelectedWagons] = useState<WagonTemplate[]>([]);
  useEffect(() => {
    if (preAdvice.wagons) {
      preAdvice.wagons.forEach((wagon: PreAdviceWagonEntity) => {
        wagonCache.findItemById(wagon.referentialData.registration).then((template) => {
          if (template) {
            setSelectedWagons([...selectedWagons, template]);
          }
        });
      });
    }
    // eslint-disable-next-line
  }, []);
  if (!preAdvice.id || preAdvice.isValidated()) {
    return null;
  }
  const success = formValid && preAdvice.getNbValidatedWagons() === preAdvice.getNbWagons();
  const hasRedLabel = () => selectedWagons.some((wagon) => wagonHasRedLabel(wagon));
  return (
    <div className="vehicles-validate">
      <button
        className={classNames('btn btn-accent btn-icon lock', {
          disabled: !preAdvice.getNbWagons() || !success || hasRedLabel(),
        })}
        onClick={() => dispatch(requestValidatePreAdvice(preAdvice, hasRedLabel()))}
        type="button"
      >
        Valider
      </button>
    </div>
  );
};

export default PreAdviceValidation;
