import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getRepairs,
  getRepairsIsError,
  getRepairsIsLoading,
  getRepairsSortDefinition,
  selectOverlay,
} from '../commons/selectors/selectors';
import { usePageTitle } from '../commons/components/hooks';
import RepairsTable from './table/RepairsTable';
import { requestResetFilters, requestUpdateRepairSort } from './RepairsDucks';
import RepairsTopBar from './filters/RepairsTopBar';
import { RepairListView } from './repairTypes';
import { useAppDispatch, useAppSelector } from '../commons/store/hooks';
import { Sort } from '../commons/model/sort';
import RepairDetails from './form/RepairDetails';
import { loadRepair } from './RepairDucks';

const RepairsListPage = () => {
  usePageTitle('Suivi des réparations');

  const dispatch = useAppDispatch();
  const error = useSelector(getRepairsIsError);
  const loading = useSelector(getRepairsIsLoading);
  const repairs = useSelector(getRepairs);
  const sortDefinition = useSelector(getRepairsSortDefinition);
  const overlay = useAppSelector(selectOverlay);

  const updateSortDefinition = (newSort: Sort<RepairListView>) => dispatch(requestUpdateRepairSort(newSort));
  const handleLoadRepair = (repairId: string) => dispatch(loadRepair(repairId));

  useEffect(
    () => () => {
      dispatch(requestResetFilters());
    },
    [dispatch],
  );

  return (
    <div className="content-parent table-page" onClick={() => false}>
      <RepairsTopBar loading={loading} />
      <RepairsTable
        error={error}
        loading={loading}
        repairs={repairs}
        sortDefinition={sortDefinition}
        updateSortDefinition={updateSortDefinition}
        onTableRowClick={handleLoadRepair}
      />
      {overlay.active === 'repair-details' && <RepairDetails />}
    </div>
  );
};

RepairsListPage.propTypes = {};

export default RepairsListPage;
