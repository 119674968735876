/**
 * Input component
 */
import React, { useRef, useState } from 'react';
import { autofill, blur, WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';
import Autocomplete from '../autocomplete/Autocomplete';
import { toPath } from '../../helpers/helpers';
import './inputStyles.scss';
import '../dropdown/dropdownStyles.scss';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import { ActionCreator } from 'redux';
import { Autocompleter } from '../../templates/Autocompleter';
import { AppAction } from '../../store/store';

type Transform = (value: any) => any;

export type InputProps<T> = React.InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Transform the (manual) input value before saving.
   */
  normalizeOnSave?: Transform;
  /**
   * Configure the autocomplete.
   */
  autocompleter?: Autocompleter<T>;
  /**
   * Filter the autocomplete results.
   */
  autocompleteFilter?: (template: T) => boolean;
  /**
   * Allow saving values that do not match any autocomplete items
   * (has an effect only for Input with autocomplete)
   */
  allowManualValues?: boolean;
  formatManualValue?: (value: string) => T;
  /**
   * The full entity this input is a part of.
   */
  data?: any;
  /**
   * Action to dispatch when the field value was updated.
   */
  saveAction?: ActionCreator<AppAction>;
  /**
   * An optional unit label to display at the end of the field.
   */
  unit?: string;
  /**
   * Field label.
   */
  labelText?: React.ReactNode;
  /**
   * Field text "icon"
   */
  iconText?: React.ReactNode;
  /**
   * Props to forward to the underlying <input> (most can be passed directly to the Input component, but this
   * can avoid props naming conflicts).
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  containerClassName?: string;
};

const getPropertyToUpdate = (input: WrappedFieldInputProps, transform?: Transform) => ({
  [input.name]: transform ? transform(input.value) : input.value,
});

const getParentPath = (input: WrappedFieldInputProps) => {
  const paths = toPath(input.name);
  return paths?.splice(0, paths.length - 1).join('.');
};

const Input = <T = string,>({
  autocompleter,
  autocompleteFilter,
  allowManualValues,
  formatManualValue,
  input,
  meta,
  data,
  normalizeOnSave,
  saveAction,
  id,
  unit,
  labelText,
  iconText,
  placeholder = 'Saisir',
  disabled,
  inputProps,
  containerClassName,
  ...otherProps
}: InputProps<T> & WrappedFieldProps) => {
  const [saving, setSaving] = useState(false);
  // Redux-form's active flag is incorrect when moving focus from one autocomplete field to another, so keep our own
  const [active, setActive] = useState(false);
  const scrolling = useRef(false);

  const autocomplete = autocompleteFilter
    ? autocompleter?.autocompleteWithFilter(autocompleteFilter)
    : autocompleter?.autocomplete;

  const onFocus = (event: React.FocusEvent) => {
    setActive(true);
    setSaving(false);

    // Call native redux form event
    input.onFocus(event);
  };

  const onBlur = (event: React.FocusEvent) => {
    setActive(false);
    if (scrolling.current) {
      return;
    }

    const saveWithoutAutoCompletion = () => {
      setSaving(true);
      if (saveAction) {
        const propertyToUpdate = getPropertyToUpdate(input, normalizeOnSave);
        meta.dispatch(saveAction(data, propertyToUpdate));
      }
    };

    if (meta.valid) {
      if (autocomplete) {
        autocomplete(input.value).then((values) => {
          if (values.length === 1) {
            onAutocompleteClick(values[0]);
          } else if (allowManualValues) {
            saveWithoutAutoCompletion();
          }
        });
      } else {
        saveWithoutAutoCompletion();
      }
    }

    // Call native redux form event
    input.onBlur(event);
  };

  const onAutocompleteScroll = () => {
    scrolling.current = true;
    setTimeout(() => {
      scrolling.current = false;
    }, 200);
  };

  const onAutocompleteClick = (entity: T) => {
    setActive(false);
    if (typeof entity === 'object') {
      const entityObject = entity as unknown as object;
      const parentPath = getParentPath(input);

      // Call native redux form event 'autofill' on every field
      // or 'blur' for this field, to also close the popup in IE.
      (Object.keys(entityObject) as Array<keyof T>).forEach((key) => {
        const fullKey = parentPath ? `${parentPath}.${String(key)}` : String(key);
        if (fullKey === input.name) {
          meta.dispatch(blur(meta.form, fullKey, entity[key]));
        } else if (fullKey !== 'id') {
          // Never update the id of the root entity
          meta.dispatch(autofill(meta.form, fullKey, entity[key]));
        }
      });
      setSaving(true);
      if (saveAction) {
        meta.dispatch(saveAction(data, parentPath ? { [parentPath]: { ...entityObject } } : { ...entityObject }));
      }
    } else {
      meta.dispatch(blur(meta.form, input.name, entity));
      setSaving(true);
      if (saveAction) {
        meta.dispatch(saveAction(data, { [input.name]: entity }));
      }
    }
  };

  return (
    <div
      className={classNames('input', containerClassName, {
        'input-label': labelText,
        'input-unit': unit,
        'input-error': meta?.touched && meta.invalid,
        'input-valid': saving,
        'input-autofill': meta?.autofilled,
      })}
    >
      {iconText && <div className="railway-company-label">{iconText}</div>}
      {labelText && <label htmlFor={id ?? otherProps.name}>{labelText}</label>}

      <input
        id={id ?? otherProps.name}
        className={classNames({ icon: iconText })}
        placeholder={disabled ? '' : placeholder}
        disabled={disabled}
        {...input}
        value={input.value ?? ''}
        {...otherProps}
        {...inputProps}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {autocompleter && autocomplete && (
        <Autocomplete
          value={input.value}
          autocomplete={autocomplete}
          formatItem={autocompleter.formatItem}
          onClick={onAutocompleteClick}
          onScroll={onAutocompleteScroll}
          allowManualValues={allowManualValues}
          formatManualValue={formatManualValue}
          active={active}
        />
      )}

      {unit ? <div className="unit">{unit}</div> : null}

      {meta?.touched && meta.error ? <div className="error-message">{meta.error}</div> : null}
    </div>
  );
};

export default Input;
