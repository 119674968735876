import offlineUtils from '../../commons/offline/offlineUtils';
import { startToast } from '../../commons/components/toast/toastDucks';
import { TrainStep } from '../../commons/model/Train';
import { createAction, createAsyncThunk, createSlice, Draft, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import trainStepCache from '../../commons/templates/trainStepCache';
import apiHelper from '../../api/apiHelper';
import ApiError from '../../api/ApiError';
import { RootState } from '../../commons/reducers/rootReducer';
import { offlineUpdateCompositionStatus, persistTrainsIfNeeded } from '../offline/offlineTrainsDucks';
import { applyTrainDiffs } from '../../commons/model/TrainDiff';
import wagonCache from '../../commons/templates/wagonCache';
import { openConfirmDialog } from '../../commons/components/modal/confirmModalDucks';
import { AppDispatch } from '../../commons/store/store';
import VehiclesUtils from '../../commons/model/VehiclesUtils';
import TrainUtils from '../../commons/model/TrainUtils';
import { selectTrainDiffs } from '../../commons/selectors/selectors';
import { BrakingBulletin } from '../../commons/model/BrakingBulletin';
import { invalidateSignedBrakingBulletinsIfNeeded } from './braking-bulletin/brakingBulletinActions';

export type TrainStepState = {
  data: TrainStep | null;
  brakingBulletin: BrakingBulletin | null;
  loading: boolean;
  /**
   * Error status code, or 0 for no error
   */
  error: number;
  vehicleIsUpdating: boolean;
};

const initialState: TrainStepState = {
  data: null,
  brakingBulletin: null,
  loading: false,
  error: 0,
  vehicleIsUpdating: false,
};

/*
 * Utilities
 */

const loadTrainStepFromCache = async (stepId: string, state: RootState): Promise<TrainStep | null> => {
  const rawCachedTrainStep = await trainStepCache.findItemById(stepId);
  if (!rawCachedTrainStep) {
    return null;
  }
  return loadAndApplyTrainStepDiff(rawCachedTrainStep, state);
};

const loadAndApplyTrainStepDiff = (step: TrainStep, state: RootState): TrainStep => {
  const diffs = selectTrainDiffs(step.id)(state);
  return applyTrainDiffs(step, diffs);
};

/*
 * Actions
 */

const preloadTrainStep = createAction<TrainStep>('trainStep/preload');

export const loadTrainStep = createAsyncThunk<
  TrainStep,
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>('trainStep/load', async (id, { getState, dispatch, rejectWithValue }) => {
  const cachedStep = await loadTrainStepFromCache(id, getState());
  if (cachedStep) {
    if (!offlineUtils.isOnline()) {
      // If offline, do not try to load the step from the API
      return cachedStep;
    } else {
      // Preload the cached step while we load from the API
      dispatch(preloadTrainStep(cachedStep));
    }
  }

  try {
    const step: TrainStep = await apiHelper.get(`/api/trains/steps/${id}`);
    await trainStepCache.saveItems([step], { clear: false });
    // Apply the latest diff to the remote train step
    return loadAndApplyTrainStepDiff(step, getState());
  } catch (e) {
    if (!offlineUtils.isOnline() && cachedStep) {
      // If the connection was lost, use the cached train
      return cachedStep;
    }
    if (e instanceof ApiError) {
      return rejectWithValue(e);
    }
    throw e;
  }
});

export const doResetTrainStep = createAsyncThunk<
  TrainStep,
  TrainStep,
  {
    dispatch: AppDispatch;
  }
>('trainStep/reset', async ({ id }, { dispatch }) => {
  try {
    const step: TrainStep = await apiHelper.post(`/api/trains/steps/${id}/reset`);
    await trainStepCache.saveItems([step], { clear: false });
    dispatch(startToast({ text: 'La composition a été réinitialisée', className: 'success' }));
    return step;
  } catch (e) {
    console.error('Error resetting step', e);
    dispatch(startToast({ text: "La composition n'a pas été réinitialisée", className: 'error' }));
    throw e;
  }
});

export const resetTrainStep = (step: TrainStep) => () => (dispatch: AppDispatch) => dispatch(doResetTrainStep(step));

const doVerifyTrainStep = (step: TrainStep) => () => (dispatch: AppDispatch, getState: () => RootState) => {
  const trainToUpdate: TrainStep = {
    ...step,
    status: 'VALIDATED',
  };
  dispatch(offlineUpdateCompositionStatus(trainToUpdate));
  const diffs = selectTrainDiffs(step.id)(getState());
  dispatch(trainStepUpdated(applyTrainDiffs(step, diffs)));
  dispatch(persistTrainsIfNeeded());

  // Update wagon templates in the background
  wagonCache.updateTemplatesFromComposition(VehiclesUtils.getWagons(trainToUpdate.vehicles)).then();

  dispatch(
    startToast({
      text: 'Train validé',
      className: 'success',
    }),
  );
};

export const verifyTrainStep = (step: TrainStep, username: string) => async (dispatch: AppDispatch) => {
  if (VehiclesUtils.getNbEngine(step.vehicles) === 0) {
    dispatch(
      startToast({
        text: 'La validation est impossible car la composition ne comporte aucun EM',
        className: 'error',
      }),
    );
  } else if (VehiclesUtils.isAllVehicleValidated(step.vehicles)) {
    if (await VehiclesUtils.doesVehicleHasErrorLabel(step.vehicles)) {
      dispatch(
        startToast({
          text: 'Alerte ! La composition contient au moins un wagon avec une étiquette rouge',
          className: 'warning',
        }),
      );
    }

    dispatch(
      openConfirmDialog({
        title: `Souhaitez-vous valider la composition en tant que ${username} ?`,
        actionText: 'Valider',
        action: doVerifyTrainStep(step),
      }),
    );
  } else {
    dispatch(
      startToast({
        text: "La validation est impossible car au moins un véhicule n'est pas validé",
        className: 'error',
      }),
    );
  }
};

export const unverifyTrainStep = (step: TrainStep) => () => (dispatch: AppDispatch, getState: () => RootState) => {
  if (TrainUtils.isStatusValidated(step)) {
    const trainToUpdate: TrainStep = {
      ...step,
      status: 'NOT_VALIDATED',
    };

    dispatch(offlineUpdateCompositionStatus(trainToUpdate));
    dispatch(invalidateSignedBrakingBulletinsIfNeeded(trainToUpdate));
    const diffs = selectTrainDiffs(step.id)(getState());
    dispatch(trainStepUpdated(applyTrainDiffs(step, diffs)));
    dispatch(persistTrainsIfNeeded());

    dispatch(
      startToast({
        text: 'Train invalidé',
        className: 'success',
      }),
    );
  } else {
    dispatch(
      startToast({
        text: "L'invalidation est impossible car la composition n'est pas validée",
        className: 'error',
      }),
    );
  }
};

/*
 * Slice
 */

export const trainStepSlice = createSlice({
  name: 'trainStep',
  initialState,
  reducers: {
    unloadTrainStep: (state) => {
      state.data = null;
      state.brakingBulletin = null;
    },
    trainStepUpdated: (state, { payload: step }: PayloadAction<TrainStep>) => {
      state.data = TrainUtils.withVehicleIndices(step) as Draft<TrainStep>;
    },
    openBrakingBulletin: (state, { payload: brakingBulletin }: PayloadAction<BrakingBulletin>) => {
      state.brakingBulletin = brakingBulletin as Draft<BrakingBulletin>;
    },
    closeBrakingBulletin: (state) => {
      state.brakingBulletin = null;
    },
    toggleVehicleUpdatingFlag: (state, { payload: updating }: PayloadAction<boolean>) => {
      state.vehicleIsUpdating = updating;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTrainStep.pending, (state, { meta: { arg } }) => {
        if (state.data && state.data.id !== arg) {
          // Clear the currently loaded train step if it is different from the requested one.
          state.data = null;
        }
        state.loading = true;
        state.error = 0;
        state.brakingBulletin = null;
      })
      .addCase(loadTrainStep.rejected, (state, { payload, error }) => {
        console.error('Error loading the train step', payload, error);
        state.loading = false;
        // noinspection SuspiciousTypeOfGuard
        if (payload instanceof ApiError) {
          state.error = payload.httpStatus;
        } else {
          state.error = -1;
        }
      })
      .addCase(preloadTrainStep, (state, { payload: step }) => {
        // Keep the "loading" flag unchanged
        state.data = TrainUtils.withVehicleIndices(step) as Draft<TrainStep>;
      })
      .addMatcher(
        isAnyOf(loadTrainStep.fulfilled, doResetTrainStep.fulfilled),
        (state, { payload: step }: PayloadAction<TrainStep>) => {
          state.loading = false;
          state.error = 0;
          state.data = TrainUtils.withVehicleIndices(step) as Draft<TrainStep>;
        },
      );
  },
});
export const {
  unloadTrainStep,
  trainStepUpdated,
  openBrakingBulletin,
  closeBrakingBulletin,
  toggleVehicleUpdatingFlag,
} = trainStepSlice.actions;
export default trainStepSlice.reducer;

export const pdfVehiclesReportUrl = (step: TrainStep) => `/api/trains/steps/${step.id}.pdf`;
export const excelVehiclesReportUrl = (step: TrainStep) => `/api/trains/steps/${step.id}.xlsx`;
