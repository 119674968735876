import DamageReportEntity from '../../commons/entity/DamageReportEntity';
import React from 'react';

const DamageLabel = ({ labelCode: code }: { labelCode: string }) => (
  <span className={`damage-label-${code}`}>
    {DamageReportEntity.LABELS.find((value) => value.value === code)?.label}
  </span>
);

export default DamageLabel;
