/**
 * Workshop field component
 */
import React from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import './inputStyles.scss';
import { Workshop } from '../../model/templates';
import workshopCache from '../../templates/workshopCache';
import { DropdownProps } from '../dropdown/Dropdown';
import Input from './Input';

type WorkshopFieldProps = BaseFieldProps & Omit<DropdownProps<Workshop>, 'options'>;

const WorkshopField = (props: WorkshopFieldProps) => {
  return (
    <Field
      {...props}
      component={Input<Workshop>}
      id="workshop-input"
      type="text"
      autocompleter={workshopCache}
      allowManualValues={true}
      formatManualValue={(value: string) => ({ id: null, name: value.toUpperCase() })}
    />
  );
};
export default WorkshopField;
